/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { Timestamp } from "./google/protobuf/timestamp";

export const protobufPackage = "tcc.v1";

export enum CustomerStatus {
  CUSTOMER_STATUS_UNSPECIFIED = "CUSTOMER_STATUS_UNSPECIFIED",
  CUSTOMER_STATUS_INTERNAL = "CUSTOMER_STATUS_INTERNAL",
  CUSTOMER_STATUS_CUSTOMER = "CUSTOMER_STATUS_CUSTOMER",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function customerStatusFromJSON(object: any): CustomerStatus {
  switch (object) {
    case 0:
    case "CUSTOMER_STATUS_UNSPECIFIED":
      return CustomerStatus.CUSTOMER_STATUS_UNSPECIFIED;
    case 1:
    case "CUSTOMER_STATUS_INTERNAL":
      return CustomerStatus.CUSTOMER_STATUS_INTERNAL;
    case 2:
    case "CUSTOMER_STATUS_CUSTOMER":
      return CustomerStatus.CUSTOMER_STATUS_CUSTOMER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return CustomerStatus.UNRECOGNIZED;
  }
}

export function customerStatusToJSON(object: CustomerStatus): string {
  switch (object) {
    case CustomerStatus.CUSTOMER_STATUS_UNSPECIFIED:
      return "CUSTOMER_STATUS_UNSPECIFIED";
    case CustomerStatus.CUSTOMER_STATUS_INTERNAL:
      return "CUSTOMER_STATUS_INTERNAL";
    case CustomerStatus.CUSTOMER_STATUS_CUSTOMER:
      return "CUSTOMER_STATUS_CUSTOMER";
    case CustomerStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export function customerStatusToNumber(object: CustomerStatus): number {
  switch (object) {
    case CustomerStatus.CUSTOMER_STATUS_UNSPECIFIED:
      return 0;
    case CustomerStatus.CUSTOMER_STATUS_INTERNAL:
      return 1;
    case CustomerStatus.CUSTOMER_STATUS_CUSTOMER:
      return 2;
    case CustomerStatus.UNRECOGNIZED:
    default:
      return -1;
  }
}

export interface GetTenantsRequest {
  /** specify a specific customer to fetch tenants for. returns all if empty. */
  customerId: string;
  customerStatus: CustomerStatus;
}

export interface GetTenantsResponse {
  tenants: Tenant[];
}

export interface GetTenantRequest {
  tenantId: string;
}

export interface GetTenantResponse {
  tenant: Tenant | undefined;
}

export interface Tenant {
  id: string;
  name: string;
  subdomain: string;
  auth0OrgId: string;
  /** DEPRECATED */
  serviceStatuses: { [key: string]: string };
  /** DEPRECATED */
  serviceErrorMessages: { [key: string]: string };
  archived: boolean;
  createdAt: Date | undefined;
  createdFromTemplateId: string;
  customerId: string;
  production: boolean;
  dbCluster: string;
  includeObjectDataFromTemplate: boolean;
  templateFragmentDeployments: TemplateFragmentDeployment[];
  objectDataDeployments: ObjectDataDeployment[];
}

export interface Tenant_ServiceStatusesEntry {
  key: string;
  value: string;
}

export interface Tenant_ServiceErrorMessagesEntry {
  key: string;
  value: string;
}

export interface GetTenantDeploymentStatusRequest {
  tenantId: string;
}

export interface GetTenantDeploymentStatusResponse {
  tenantId: string;
  status: string;
}

export interface GetCustomersRequest {
}

export interface GetCustomersResponse {
  customers: Customer[];
}

export interface GetCustomerRequest {
  customerId: string;
}

export interface GetCustomerResponse {
  customer: Customer | undefined;
  tenants: Tenant[];
}

export interface CreateCustomerRequest {
  customer: Customer | undefined;
  firstUser: User | undefined;
}

export interface CreateCustomerResponse {
  customer:
    | Customer
    | undefined;
  /** The tenants created for the customer as part of the creation process */
  tenants: Tenant[];
  /** Deprecated */
  firstUser: User | undefined;
}

export interface UpdateCustomerStatusRequest {
  customerId: string;
  status: CustomerStatus;
}

export interface UpdateCustomerStatusResponse {
  customer: Customer | undefined;
}

export interface Customer {
  id: string;
  name: string;
  /**
   * The shortened name for the customer. Used to generate subdomains:
   * e.g. the customer may be "Foobar Inc.", and the short_name is foobar,
   * so domains would be foobar.<...>, foobar-test.<...>, foobar-dev.<...>, etc
   *
   * This field should ideally be 10ish or fewer alphabetical characters
   */
  shortName: string;
  accountNumber: string;
  status: CustomerStatus;
}

export interface User {
  name: string;
  email: string;
  givenName: string;
  familyName: string;
}

export interface GetTenantTemplatesRequest {
}

export interface GetTenantTemplatesResponse {
  tenantTemplates: TenantTemplate[];
}

export interface TenantTemplate {
  id: string;
  name: string;
  configuration: string;
  description: string;
  golden: boolean;
  archived: boolean;
}

export interface CreateTenantFromTemplateRequest {
  customerId: string;
  templateId: string;
  tenantName: string;
  tenantSubdomain: string;
  /**
   * A bootstrap user to associate allow to perform initial login
   * Will be nil if the `enable_tendo_google_oauth` is true.
   */
  firstUser:
    | User
    | undefined;
  /**
   * If true, will configure the tenant to allow login via Tendo's
   * Google OAuth connection. This should only be used in non-production
   * stages.
   */
  enableTendoEmployeeAccess: boolean;
  /**
   * Whether to signal within TCC that this is a production tenant.
   * Production tenants may have special properties and be handled differently
   */
  isProduction: boolean;
  /**
   * If set, will deploy the template along with associated data. If the template
   * has no associated data, setting this to true has no effect.
   */
  includeObjectData: boolean;
  /**
   * If set, will use these object data sets instead of whatever is associated
   * with the template. If include_object_data is false, this field has no effect.
   */
  overrideObjectDataIds: string[];
  /**
   * The database cluster into which object service should provision a database
   * for the tenant
   */
  dbCluster: string;
  /**
   * If set, will provision demo users using the object data included with the
   * template
   */
  provisionDemoUsers: boolean;
  /** The base email address of the user who will be receive the demo user emails */
  demoUserBaseEmail: string;
  /**
   * If set, will create auth0 accounts for each Person email in the template's
   * object data, requires demo_user_base_email to be set
   */
  provisionDemoUserAuth0Accounts: boolean;
}

export interface CreateTenantFromTemplateResponse {
  tenant: Tenant | undefined;
}

export interface DestroyTenantRequest {
  tenantId: string;
}

export interface DestroyTenantResponse {
  tenant: Tenant | undefined;
}

export interface DeleteTenantRequest {
  tenantId: string;
  force: boolean;
}

export interface DeleteTenantResponse {
  tenant: Tenant | undefined;
}

export interface InitiateTenantExportRequest {
  tenantId: string;
  templateName: string;
  templateDescription: string;
  includeObjectData: boolean;
  excludedObjectDefinitions: string[];
}

export interface InitiateTenantExportResponse {
  templateId: string;
}

export interface GetTenantDestructionsRequest {
  tenantId: string;
}

export interface GetTenantDestructionsResponse {
  tenantDestructions: TenantDestruction[];
}

export interface TenantDestruction {
  service: string;
  status: string;
  errorMessage: string;
  name: string;
}

export interface GetTemplateFragmentDeploymentsRequest {
  tenantId: string;
}

export interface GetTemplateFragmentDeploymentsResponse {
  templateFragmentDeployments: TemplateFragmentDeployment[];
}

export interface TemplateFragmentDeployment {
  service: string;
  name: string;
  status: string;
  errorMessage: string;
}

export interface GetRenderedTemplateRequest {
  templateId: string;
}

export interface GetRenderedTemplateResponse {
  templateData: Uint8Array;
}

export interface GetRenderedTemplateFragmentRequest {
  templateFragmentId: string;
}

export interface GetRenderedTemplateFragmentResponse {
  templateFragmentData: Uint8Array;
}

export interface UploadTemplateRequest {
  templateData: Uint8Array;
}

export interface UploadTemplateResponse {
  templateId: string;
}

export interface GetTemplatesRequest {
  deployable: boolean;
}

export interface GetTemplatesResponse {
  templates: Template[];
}

export interface GetTemplateRequest {
  templateId: string;
}

export interface GetTemplateResponse {
  template: Template | undefined;
}

export interface GetTemplateCreationStatusRequest {
  templateId: string;
}

export interface GetTemplateCreationStatusResponse {
  templateId: string;
  status: string;
}

export interface Template {
  id: string;
  name: string;
  description: string;
  templateFragments: TemplateFragment[];
  golden: boolean;
  archived: boolean;
  published: boolean;
  createdAt: Date | undefined;
  createdFromTenantId: string;
  includesObjectData: boolean;
  /**
   * Contains a list of object data sets associated with the template.
   * If `includes_object_data` is false, this will be nil.
   */
  objectData: ObjectData[];
  emrless: boolean;
}

export interface TemplateFragment {
  id: string;
  name: string;
  service: string;
  status: string;
  errorMessage: string;
  createdAt: Date | undefined;
  updatedAt: Date | undefined;
}

export interface UpdateTemplateGoldenStatusRequest {
  templateId: string;
  golden: boolean;
}

export interface UpdateTemplateGoldenStatusResponse {
  templateId: string;
  golden: boolean;
}

export interface UpdateTemplatePublishedStatusRequest {
  templateId: string;
  published: boolean;
}

export interface UpdateTemplatePublishedStatusResponse {
  templateId: string;
  published: boolean;
}

export interface UpdateTemplateEmrlessStatusRequest {
  templateId: string;
  emrless: boolean;
  /**
   * If specified, will not attempt to merge/prune the golden master
   * EMRless template fragments to/from the specified template
   */
  disableTemplateModification: boolean;
}

export interface UpdateTemplateEmrlessStatusResponse {
  templateId: string;
  emrless: boolean;
}

export interface UpdateTemplateRequest {
  templateId: string;
  name: string;
  description: string;
}

export interface UpdateTemplateResponse {
  templateId: string;
  name: string;
  description: string;
}

export interface DeleteTemplateRequest {
  templateId: string;
}

export interface DeleteTemplateResponse {
  templateId: string;
  name: string;
  description: string;
  golden: boolean;
  archived: boolean;
}

export interface RefreshTemplateRequest {
  templateId: string;
  includeObjectData: boolean;
}

export interface RefreshTemplateResponse {
  templateId: string;
}

export interface ObjectData {
  id: string;
  model: string;
  numberRecords: number;
  status: string;
  createdAt: Date | undefined;
  updatedAt: Date | undefined;
}

export interface UploadObjectDataRequest {
  templateId: string;
  data?: Uint8Array | undefined;
  referenceId?: string | undefined;
}

export interface GetObjectDataUploadUrlRequest {
  /** Optional. Id of the template that that the data will be connected to. */
  templateId?: string | undefined;
}

export interface GetObjectDataUploadUrlResponse {
  /** Required. Url to be used to upload the zip file. The url is presigned with an expiration of 15 min. */
  url: string;
  /** Required. Id of the data. */
  referenceId: string;
  /** Headers required when making a http request using a presigned request. */
  headers: { [key: string]: string };
}

export interface GetObjectDataUploadUrlResponse_HeadersEntry {
  key: string;
  value: string;
}

export interface UploadObjectDataResponse {
  objectData: ObjectData[];
}

export interface ListObjectDBClustersRequest {
}

export interface ListObjectDBClustersResponse {
  dbClusters: string[];
}

export interface TendoObjectDefinition {
  name: string;
}

export interface ListObjectDefinitionsRequest {
  tenantId: string;
}

export interface ListObjectDefinitionsResponse {
  objectDefinitions: TendoObjectDefinition[];
}

export interface GetObjectDataRequest {
  templateId: string;
}

export interface GetObjectDataResponse {
  objectData: ObjectData[];
}

export interface GetObjectDataPackageRequest {
  templateId: string;
}

export interface GetObjectDataPackageResponse {
  downloadUrl: string;
}

export interface ObjectDataDeployment {
  id: string;
  model: string;
  numberRecords: number;
  status: string;
  createdAt: Date | undefined;
  updatedAt: Date | undefined;
}

export interface GetObjectDataDeploymentsRequest {
  tenantId: string;
}

export interface GetObjectDataDeploymentsResponse {
  objectDataDeployments: ObjectDataDeployment[];
}

export interface DeployObjectDataRequest {
  tenantId: string;
  objectDataIds: string[];
}

export interface DeployObjectDataResponse {
  objectDataDeployments: ObjectDataDeployment[];
}

export interface UserProvisioningDeployment {
  id: string;
  status: string;
  errorMessage: string;
  executionId: string;
  demoUserEmail: string;
}

export interface GetUserProvisioningDeploymentsRequest {
  tenantId: string;
}

export interface GetUserProvisioningDeploymentsResponse {
  userProvisioningDeployments: UserProvisioningDeployment[];
}

function createBaseGetTenantsRequest(): GetTenantsRequest {
  return { customerId: "", customerStatus: CustomerStatus.CUSTOMER_STATUS_UNSPECIFIED };
}

export const GetTenantsRequest = {
  encode(message: GetTenantsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.customerId !== "") {
      writer.uint32(10).string(message.customerId);
    }
    if (message.customerStatus !== CustomerStatus.CUSTOMER_STATUS_UNSPECIFIED) {
      writer.uint32(16).int32(customerStatusToNumber(message.customerStatus));
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetTenantsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetTenantsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.customerId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.customerStatus = customerStatusFromJSON(reader.int32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetTenantsRequest {
    return {
      customerId: isSet(object.customerId) ? globalThis.String(object.customerId) : "",
      customerStatus: isSet(object.customerStatus)
        ? customerStatusFromJSON(object.customerStatus)
        : CustomerStatus.CUSTOMER_STATUS_UNSPECIFIED,
    };
  },

  toJSON(message: GetTenantsRequest): unknown {
    const obj: any = {};
    if (message.customerId !== "") {
      obj.customerId = message.customerId;
    }
    if (message.customerStatus !== CustomerStatus.CUSTOMER_STATUS_UNSPECIFIED) {
      obj.customerStatus = customerStatusToJSON(message.customerStatus);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetTenantsRequest>, I>>(base?: I): GetTenantsRequest {
    return GetTenantsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetTenantsRequest>, I>>(object: I): GetTenantsRequest {
    const message = createBaseGetTenantsRequest();
    message.customerId = object.customerId ?? "";
    message.customerStatus = object.customerStatus ?? CustomerStatus.CUSTOMER_STATUS_UNSPECIFIED;
    return message;
  },
};

function createBaseGetTenantsResponse(): GetTenantsResponse {
  return { tenants: [] };
}

export const GetTenantsResponse = {
  encode(message: GetTenantsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.tenants) {
      Tenant.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetTenantsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetTenantsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tenants.push(Tenant.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetTenantsResponse {
    return {
      tenants: globalThis.Array.isArray(object?.tenants) ? object.tenants.map((e: any) => Tenant.fromJSON(e)) : [],
    };
  },

  toJSON(message: GetTenantsResponse): unknown {
    const obj: any = {};
    if (message.tenants?.length) {
      obj.tenants = message.tenants.map((e) => Tenant.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetTenantsResponse>, I>>(base?: I): GetTenantsResponse {
    return GetTenantsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetTenantsResponse>, I>>(object: I): GetTenantsResponse {
    const message = createBaseGetTenantsResponse();
    message.tenants = object.tenants?.map((e) => Tenant.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetTenantRequest(): GetTenantRequest {
  return { tenantId: "" };
}

export const GetTenantRequest = {
  encode(message: GetTenantRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tenantId !== "") {
      writer.uint32(10).string(message.tenantId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetTenantRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetTenantRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tenantId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetTenantRequest {
    return { tenantId: isSet(object.tenantId) ? globalThis.String(object.tenantId) : "" };
  },

  toJSON(message: GetTenantRequest): unknown {
    const obj: any = {};
    if (message.tenantId !== "") {
      obj.tenantId = message.tenantId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetTenantRequest>, I>>(base?: I): GetTenantRequest {
    return GetTenantRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetTenantRequest>, I>>(object: I): GetTenantRequest {
    const message = createBaseGetTenantRequest();
    message.tenantId = object.tenantId ?? "";
    return message;
  },
};

function createBaseGetTenantResponse(): GetTenantResponse {
  return { tenant: undefined };
}

export const GetTenantResponse = {
  encode(message: GetTenantResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tenant !== undefined) {
      Tenant.encode(message.tenant, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetTenantResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetTenantResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tenant = Tenant.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetTenantResponse {
    return { tenant: isSet(object.tenant) ? Tenant.fromJSON(object.tenant) : undefined };
  },

  toJSON(message: GetTenantResponse): unknown {
    const obj: any = {};
    if (message.tenant !== undefined) {
      obj.tenant = Tenant.toJSON(message.tenant);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetTenantResponse>, I>>(base?: I): GetTenantResponse {
    return GetTenantResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetTenantResponse>, I>>(object: I): GetTenantResponse {
    const message = createBaseGetTenantResponse();
    message.tenant = (object.tenant !== undefined && object.tenant !== null)
      ? Tenant.fromPartial(object.tenant)
      : undefined;
    return message;
  },
};

function createBaseTenant(): Tenant {
  return {
    id: "",
    name: "",
    subdomain: "",
    auth0OrgId: "",
    serviceStatuses: {},
    serviceErrorMessages: {},
    archived: false,
    createdAt: undefined,
    createdFromTemplateId: "",
    customerId: "",
    production: false,
    dbCluster: "",
    includeObjectDataFromTemplate: false,
    templateFragmentDeployments: [],
    objectDataDeployments: [],
  };
}

export const Tenant = {
  encode(message: Tenant, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.subdomain !== "") {
      writer.uint32(26).string(message.subdomain);
    }
    if (message.auth0OrgId !== "") {
      writer.uint32(34).string(message.auth0OrgId);
    }
    Object.entries(message.serviceStatuses).forEach(([key, value]) => {
      Tenant_ServiceStatusesEntry.encode({ key: key as any, value }, writer.uint32(42).fork()).ldelim();
    });
    Object.entries(message.serviceErrorMessages).forEach(([key, value]) => {
      Tenant_ServiceErrorMessagesEntry.encode({ key: key as any, value }, writer.uint32(50).fork()).ldelim();
    });
    if (message.archived !== false) {
      writer.uint32(56).bool(message.archived);
    }
    if (message.createdAt !== undefined) {
      Timestamp.encode(toTimestamp(message.createdAt), writer.uint32(66).fork()).ldelim();
    }
    if (message.createdFromTemplateId !== "") {
      writer.uint32(74).string(message.createdFromTemplateId);
    }
    if (message.customerId !== "") {
      writer.uint32(82).string(message.customerId);
    }
    if (message.production !== false) {
      writer.uint32(88).bool(message.production);
    }
    if (message.dbCluster !== "") {
      writer.uint32(98).string(message.dbCluster);
    }
    if (message.includeObjectDataFromTemplate !== false) {
      writer.uint32(104).bool(message.includeObjectDataFromTemplate);
    }
    for (const v of message.templateFragmentDeployments) {
      TemplateFragmentDeployment.encode(v!, writer.uint32(114).fork()).ldelim();
    }
    for (const v of message.objectDataDeployments) {
      ObjectDataDeployment.encode(v!, writer.uint32(122).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Tenant {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTenant();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.subdomain = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.auth0OrgId = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          const entry5 = Tenant_ServiceStatusesEntry.decode(reader, reader.uint32());
          if (entry5.value !== undefined) {
            message.serviceStatuses[entry5.key] = entry5.value;
          }
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          const entry6 = Tenant_ServiceErrorMessagesEntry.decode(reader, reader.uint32());
          if (entry6.value !== undefined) {
            message.serviceErrorMessages[entry6.key] = entry6.value;
          }
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.archived = reader.bool();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.createdAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.createdFromTemplateId = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.customerId = reader.string();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.production = reader.bool();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.dbCluster = reader.string();
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.includeObjectDataFromTemplate = reader.bool();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.templateFragmentDeployments.push(TemplateFragmentDeployment.decode(reader, reader.uint32()));
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.objectDataDeployments.push(ObjectDataDeployment.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Tenant {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      subdomain: isSet(object.subdomain) ? globalThis.String(object.subdomain) : "",
      auth0OrgId: isSet(object.auth0OrgId) ? globalThis.String(object.auth0OrgId) : "",
      serviceStatuses: isObject(object.serviceStatuses)
        ? Object.entries(object.serviceStatuses).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
      serviceErrorMessages: isObject(object.serviceErrorMessages)
        ? Object.entries(object.serviceErrorMessages).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
      archived: isSet(object.archived) ? globalThis.Boolean(object.archived) : false,
      createdAt: isSet(object.createdAt) ? fromJsonTimestamp(object.createdAt) : undefined,
      createdFromTemplateId: isSet(object.createdFromTemplateId) ? globalThis.String(object.createdFromTemplateId) : "",
      customerId: isSet(object.customerId) ? globalThis.String(object.customerId) : "",
      production: isSet(object.production) ? globalThis.Boolean(object.production) : false,
      dbCluster: isSet(object.dbCluster) ? globalThis.String(object.dbCluster) : "",
      includeObjectDataFromTemplate: isSet(object.includeObjectDataFromTemplate)
        ? globalThis.Boolean(object.includeObjectDataFromTemplate)
        : false,
      templateFragmentDeployments: globalThis.Array.isArray(object?.templateFragmentDeployments)
        ? object.templateFragmentDeployments.map((e: any) => TemplateFragmentDeployment.fromJSON(e))
        : [],
      objectDataDeployments: globalThis.Array.isArray(object?.objectDataDeployments)
        ? object.objectDataDeployments.map((e: any) => ObjectDataDeployment.fromJSON(e))
        : [],
    };
  },

  toJSON(message: Tenant): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.subdomain !== "") {
      obj.subdomain = message.subdomain;
    }
    if (message.auth0OrgId !== "") {
      obj.auth0OrgId = message.auth0OrgId;
    }
    if (message.serviceStatuses) {
      const entries = Object.entries(message.serviceStatuses);
      if (entries.length > 0) {
        obj.serviceStatuses = {};
        entries.forEach(([k, v]) => {
          obj.serviceStatuses[k] = v;
        });
      }
    }
    if (message.serviceErrorMessages) {
      const entries = Object.entries(message.serviceErrorMessages);
      if (entries.length > 0) {
        obj.serviceErrorMessages = {};
        entries.forEach(([k, v]) => {
          obj.serviceErrorMessages[k] = v;
        });
      }
    }
    if (message.archived !== false) {
      obj.archived = message.archived;
    }
    if (message.createdAt !== undefined) {
      obj.createdAt = message.createdAt.toISOString();
    }
    if (message.createdFromTemplateId !== "") {
      obj.createdFromTemplateId = message.createdFromTemplateId;
    }
    if (message.customerId !== "") {
      obj.customerId = message.customerId;
    }
    if (message.production !== false) {
      obj.production = message.production;
    }
    if (message.dbCluster !== "") {
      obj.dbCluster = message.dbCluster;
    }
    if (message.includeObjectDataFromTemplate !== false) {
      obj.includeObjectDataFromTemplate = message.includeObjectDataFromTemplate;
    }
    if (message.templateFragmentDeployments?.length) {
      obj.templateFragmentDeployments = message.templateFragmentDeployments.map((e) =>
        TemplateFragmentDeployment.toJSON(e)
      );
    }
    if (message.objectDataDeployments?.length) {
      obj.objectDataDeployments = message.objectDataDeployments.map((e) => ObjectDataDeployment.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Tenant>, I>>(base?: I): Tenant {
    return Tenant.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Tenant>, I>>(object: I): Tenant {
    const message = createBaseTenant();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.subdomain = object.subdomain ?? "";
    message.auth0OrgId = object.auth0OrgId ?? "";
    message.serviceStatuses = Object.entries(object.serviceStatuses ?? {}).reduce<{ [key: string]: string }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = globalThis.String(value);
        }
        return acc;
      },
      {},
    );
    message.serviceErrorMessages = Object.entries(object.serviceErrorMessages ?? {}).reduce<{ [key: string]: string }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = globalThis.String(value);
        }
        return acc;
      },
      {},
    );
    message.archived = object.archived ?? false;
    message.createdAt = object.createdAt ?? undefined;
    message.createdFromTemplateId = object.createdFromTemplateId ?? "";
    message.customerId = object.customerId ?? "";
    message.production = object.production ?? false;
    message.dbCluster = object.dbCluster ?? "";
    message.includeObjectDataFromTemplate = object.includeObjectDataFromTemplate ?? false;
    message.templateFragmentDeployments =
      object.templateFragmentDeployments?.map((e) => TemplateFragmentDeployment.fromPartial(e)) || [];
    message.objectDataDeployments = object.objectDataDeployments?.map((e) => ObjectDataDeployment.fromPartial(e)) || [];
    return message;
  },
};

function createBaseTenant_ServiceStatusesEntry(): Tenant_ServiceStatusesEntry {
  return { key: "", value: "" };
}

export const Tenant_ServiceStatusesEntry = {
  encode(message: Tenant_ServiceStatusesEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Tenant_ServiceStatusesEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTenant_ServiceStatusesEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Tenant_ServiceStatusesEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? globalThis.String(object.value) : "",
    };
  },

  toJSON(message: Tenant_ServiceStatusesEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Tenant_ServiceStatusesEntry>, I>>(base?: I): Tenant_ServiceStatusesEntry {
    return Tenant_ServiceStatusesEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Tenant_ServiceStatusesEntry>, I>>(object: I): Tenant_ServiceStatusesEntry {
    const message = createBaseTenant_ServiceStatusesEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseTenant_ServiceErrorMessagesEntry(): Tenant_ServiceErrorMessagesEntry {
  return { key: "", value: "" };
}

export const Tenant_ServiceErrorMessagesEntry = {
  encode(message: Tenant_ServiceErrorMessagesEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Tenant_ServiceErrorMessagesEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTenant_ServiceErrorMessagesEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Tenant_ServiceErrorMessagesEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? globalThis.String(object.value) : "",
    };
  },

  toJSON(message: Tenant_ServiceErrorMessagesEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Tenant_ServiceErrorMessagesEntry>, I>>(
    base?: I,
  ): Tenant_ServiceErrorMessagesEntry {
    return Tenant_ServiceErrorMessagesEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Tenant_ServiceErrorMessagesEntry>, I>>(
    object: I,
  ): Tenant_ServiceErrorMessagesEntry {
    const message = createBaseTenant_ServiceErrorMessagesEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseGetTenantDeploymentStatusRequest(): GetTenantDeploymentStatusRequest {
  return { tenantId: "" };
}

export const GetTenantDeploymentStatusRequest = {
  encode(message: GetTenantDeploymentStatusRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tenantId !== "") {
      writer.uint32(10).string(message.tenantId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetTenantDeploymentStatusRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetTenantDeploymentStatusRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tenantId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetTenantDeploymentStatusRequest {
    return { tenantId: isSet(object.tenantId) ? globalThis.String(object.tenantId) : "" };
  },

  toJSON(message: GetTenantDeploymentStatusRequest): unknown {
    const obj: any = {};
    if (message.tenantId !== "") {
      obj.tenantId = message.tenantId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetTenantDeploymentStatusRequest>, I>>(
    base?: I,
  ): GetTenantDeploymentStatusRequest {
    return GetTenantDeploymentStatusRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetTenantDeploymentStatusRequest>, I>>(
    object: I,
  ): GetTenantDeploymentStatusRequest {
    const message = createBaseGetTenantDeploymentStatusRequest();
    message.tenantId = object.tenantId ?? "";
    return message;
  },
};

function createBaseGetTenantDeploymentStatusResponse(): GetTenantDeploymentStatusResponse {
  return { tenantId: "", status: "" };
}

export const GetTenantDeploymentStatusResponse = {
  encode(message: GetTenantDeploymentStatusResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tenantId !== "") {
      writer.uint32(10).string(message.tenantId);
    }
    if (message.status !== "") {
      writer.uint32(18).string(message.status);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetTenantDeploymentStatusResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetTenantDeploymentStatusResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tenantId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.status = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetTenantDeploymentStatusResponse {
    return {
      tenantId: isSet(object.tenantId) ? globalThis.String(object.tenantId) : "",
      status: isSet(object.status) ? globalThis.String(object.status) : "",
    };
  },

  toJSON(message: GetTenantDeploymentStatusResponse): unknown {
    const obj: any = {};
    if (message.tenantId !== "") {
      obj.tenantId = message.tenantId;
    }
    if (message.status !== "") {
      obj.status = message.status;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetTenantDeploymentStatusResponse>, I>>(
    base?: I,
  ): GetTenantDeploymentStatusResponse {
    return GetTenantDeploymentStatusResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetTenantDeploymentStatusResponse>, I>>(
    object: I,
  ): GetTenantDeploymentStatusResponse {
    const message = createBaseGetTenantDeploymentStatusResponse();
    message.tenantId = object.tenantId ?? "";
    message.status = object.status ?? "";
    return message;
  },
};

function createBaseGetCustomersRequest(): GetCustomersRequest {
  return {};
}

export const GetCustomersRequest = {
  encode(_: GetCustomersRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetCustomersRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetCustomersRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): GetCustomersRequest {
    return {};
  },

  toJSON(_: GetCustomersRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<GetCustomersRequest>, I>>(base?: I): GetCustomersRequest {
    return GetCustomersRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetCustomersRequest>, I>>(_: I): GetCustomersRequest {
    const message = createBaseGetCustomersRequest();
    return message;
  },
};

function createBaseGetCustomersResponse(): GetCustomersResponse {
  return { customers: [] };
}

export const GetCustomersResponse = {
  encode(message: GetCustomersResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.customers) {
      Customer.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetCustomersResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetCustomersResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.customers.push(Customer.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetCustomersResponse {
    return {
      customers: globalThis.Array.isArray(object?.customers)
        ? object.customers.map((e: any) => Customer.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GetCustomersResponse): unknown {
    const obj: any = {};
    if (message.customers?.length) {
      obj.customers = message.customers.map((e) => Customer.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetCustomersResponse>, I>>(base?: I): GetCustomersResponse {
    return GetCustomersResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetCustomersResponse>, I>>(object: I): GetCustomersResponse {
    const message = createBaseGetCustomersResponse();
    message.customers = object.customers?.map((e) => Customer.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetCustomerRequest(): GetCustomerRequest {
  return { customerId: "" };
}

export const GetCustomerRequest = {
  encode(message: GetCustomerRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.customerId !== "") {
      writer.uint32(10).string(message.customerId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetCustomerRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetCustomerRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.customerId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetCustomerRequest {
    return { customerId: isSet(object.customerId) ? globalThis.String(object.customerId) : "" };
  },

  toJSON(message: GetCustomerRequest): unknown {
    const obj: any = {};
    if (message.customerId !== "") {
      obj.customerId = message.customerId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetCustomerRequest>, I>>(base?: I): GetCustomerRequest {
    return GetCustomerRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetCustomerRequest>, I>>(object: I): GetCustomerRequest {
    const message = createBaseGetCustomerRequest();
    message.customerId = object.customerId ?? "";
    return message;
  },
};

function createBaseGetCustomerResponse(): GetCustomerResponse {
  return { customer: undefined, tenants: [] };
}

export const GetCustomerResponse = {
  encode(message: GetCustomerResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.customer !== undefined) {
      Customer.encode(message.customer, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.tenants) {
      Tenant.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetCustomerResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetCustomerResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.customer = Customer.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.tenants.push(Tenant.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetCustomerResponse {
    return {
      customer: isSet(object.customer) ? Customer.fromJSON(object.customer) : undefined,
      tenants: globalThis.Array.isArray(object?.tenants) ? object.tenants.map((e: any) => Tenant.fromJSON(e)) : [],
    };
  },

  toJSON(message: GetCustomerResponse): unknown {
    const obj: any = {};
    if (message.customer !== undefined) {
      obj.customer = Customer.toJSON(message.customer);
    }
    if (message.tenants?.length) {
      obj.tenants = message.tenants.map((e) => Tenant.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetCustomerResponse>, I>>(base?: I): GetCustomerResponse {
    return GetCustomerResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetCustomerResponse>, I>>(object: I): GetCustomerResponse {
    const message = createBaseGetCustomerResponse();
    message.customer = (object.customer !== undefined && object.customer !== null)
      ? Customer.fromPartial(object.customer)
      : undefined;
    message.tenants = object.tenants?.map((e) => Tenant.fromPartial(e)) || [];
    return message;
  },
};

function createBaseCreateCustomerRequest(): CreateCustomerRequest {
  return { customer: undefined, firstUser: undefined };
}

export const CreateCustomerRequest = {
  encode(message: CreateCustomerRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.customer !== undefined) {
      Customer.encode(message.customer, writer.uint32(10).fork()).ldelim();
    }
    if (message.firstUser !== undefined) {
      User.encode(message.firstUser, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateCustomerRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateCustomerRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.customer = Customer.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.firstUser = User.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateCustomerRequest {
    return {
      customer: isSet(object.customer) ? Customer.fromJSON(object.customer) : undefined,
      firstUser: isSet(object.firstUser) ? User.fromJSON(object.firstUser) : undefined,
    };
  },

  toJSON(message: CreateCustomerRequest): unknown {
    const obj: any = {};
    if (message.customer !== undefined) {
      obj.customer = Customer.toJSON(message.customer);
    }
    if (message.firstUser !== undefined) {
      obj.firstUser = User.toJSON(message.firstUser);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateCustomerRequest>, I>>(base?: I): CreateCustomerRequest {
    return CreateCustomerRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateCustomerRequest>, I>>(object: I): CreateCustomerRequest {
    const message = createBaseCreateCustomerRequest();
    message.customer = (object.customer !== undefined && object.customer !== null)
      ? Customer.fromPartial(object.customer)
      : undefined;
    message.firstUser = (object.firstUser !== undefined && object.firstUser !== null)
      ? User.fromPartial(object.firstUser)
      : undefined;
    return message;
  },
};

function createBaseCreateCustomerResponse(): CreateCustomerResponse {
  return { customer: undefined, tenants: [], firstUser: undefined };
}

export const CreateCustomerResponse = {
  encode(message: CreateCustomerResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.customer !== undefined) {
      Customer.encode(message.customer, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.tenants) {
      Tenant.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    if (message.firstUser !== undefined) {
      User.encode(message.firstUser, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateCustomerResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateCustomerResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.customer = Customer.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.tenants.push(Tenant.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.firstUser = User.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateCustomerResponse {
    return {
      customer: isSet(object.customer) ? Customer.fromJSON(object.customer) : undefined,
      tenants: globalThis.Array.isArray(object?.tenants) ? object.tenants.map((e: any) => Tenant.fromJSON(e)) : [],
      firstUser: isSet(object.firstUser) ? User.fromJSON(object.firstUser) : undefined,
    };
  },

  toJSON(message: CreateCustomerResponse): unknown {
    const obj: any = {};
    if (message.customer !== undefined) {
      obj.customer = Customer.toJSON(message.customer);
    }
    if (message.tenants?.length) {
      obj.tenants = message.tenants.map((e) => Tenant.toJSON(e));
    }
    if (message.firstUser !== undefined) {
      obj.firstUser = User.toJSON(message.firstUser);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateCustomerResponse>, I>>(base?: I): CreateCustomerResponse {
    return CreateCustomerResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateCustomerResponse>, I>>(object: I): CreateCustomerResponse {
    const message = createBaseCreateCustomerResponse();
    message.customer = (object.customer !== undefined && object.customer !== null)
      ? Customer.fromPartial(object.customer)
      : undefined;
    message.tenants = object.tenants?.map((e) => Tenant.fromPartial(e)) || [];
    message.firstUser = (object.firstUser !== undefined && object.firstUser !== null)
      ? User.fromPartial(object.firstUser)
      : undefined;
    return message;
  },
};

function createBaseUpdateCustomerStatusRequest(): UpdateCustomerStatusRequest {
  return { customerId: "", status: CustomerStatus.CUSTOMER_STATUS_UNSPECIFIED };
}

export const UpdateCustomerStatusRequest = {
  encode(message: UpdateCustomerStatusRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.customerId !== "") {
      writer.uint32(10).string(message.customerId);
    }
    if (message.status !== CustomerStatus.CUSTOMER_STATUS_UNSPECIFIED) {
      writer.uint32(16).int32(customerStatusToNumber(message.status));
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateCustomerStatusRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateCustomerStatusRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.customerId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.status = customerStatusFromJSON(reader.int32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateCustomerStatusRequest {
    return {
      customerId: isSet(object.customerId) ? globalThis.String(object.customerId) : "",
      status: isSet(object.status) ? customerStatusFromJSON(object.status) : CustomerStatus.CUSTOMER_STATUS_UNSPECIFIED,
    };
  },

  toJSON(message: UpdateCustomerStatusRequest): unknown {
    const obj: any = {};
    if (message.customerId !== "") {
      obj.customerId = message.customerId;
    }
    if (message.status !== CustomerStatus.CUSTOMER_STATUS_UNSPECIFIED) {
      obj.status = customerStatusToJSON(message.status);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateCustomerStatusRequest>, I>>(base?: I): UpdateCustomerStatusRequest {
    return UpdateCustomerStatusRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateCustomerStatusRequest>, I>>(object: I): UpdateCustomerStatusRequest {
    const message = createBaseUpdateCustomerStatusRequest();
    message.customerId = object.customerId ?? "";
    message.status = object.status ?? CustomerStatus.CUSTOMER_STATUS_UNSPECIFIED;
    return message;
  },
};

function createBaseUpdateCustomerStatusResponse(): UpdateCustomerStatusResponse {
  return { customer: undefined };
}

export const UpdateCustomerStatusResponse = {
  encode(message: UpdateCustomerStatusResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.customer !== undefined) {
      Customer.encode(message.customer, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateCustomerStatusResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateCustomerStatusResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.customer = Customer.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateCustomerStatusResponse {
    return { customer: isSet(object.customer) ? Customer.fromJSON(object.customer) : undefined };
  },

  toJSON(message: UpdateCustomerStatusResponse): unknown {
    const obj: any = {};
    if (message.customer !== undefined) {
      obj.customer = Customer.toJSON(message.customer);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateCustomerStatusResponse>, I>>(base?: I): UpdateCustomerStatusResponse {
    return UpdateCustomerStatusResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateCustomerStatusResponse>, I>>(object: I): UpdateCustomerStatusResponse {
    const message = createBaseUpdateCustomerStatusResponse();
    message.customer = (object.customer !== undefined && object.customer !== null)
      ? Customer.fromPartial(object.customer)
      : undefined;
    return message;
  },
};

function createBaseCustomer(): Customer {
  return { id: "", name: "", shortName: "", accountNumber: "", status: CustomerStatus.CUSTOMER_STATUS_UNSPECIFIED };
}

export const Customer = {
  encode(message: Customer, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.shortName !== "") {
      writer.uint32(26).string(message.shortName);
    }
    if (message.accountNumber !== "") {
      writer.uint32(34).string(message.accountNumber);
    }
    if (message.status !== CustomerStatus.CUSTOMER_STATUS_UNSPECIFIED) {
      writer.uint32(40).int32(customerStatusToNumber(message.status));
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Customer {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCustomer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.shortName = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.accountNumber = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.status = customerStatusFromJSON(reader.int32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Customer {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      shortName: isSet(object.shortName) ? globalThis.String(object.shortName) : "",
      accountNumber: isSet(object.accountNumber) ? globalThis.String(object.accountNumber) : "",
      status: isSet(object.status) ? customerStatusFromJSON(object.status) : CustomerStatus.CUSTOMER_STATUS_UNSPECIFIED,
    };
  },

  toJSON(message: Customer): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.shortName !== "") {
      obj.shortName = message.shortName;
    }
    if (message.accountNumber !== "") {
      obj.accountNumber = message.accountNumber;
    }
    if (message.status !== CustomerStatus.CUSTOMER_STATUS_UNSPECIFIED) {
      obj.status = customerStatusToJSON(message.status);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Customer>, I>>(base?: I): Customer {
    return Customer.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Customer>, I>>(object: I): Customer {
    const message = createBaseCustomer();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.shortName = object.shortName ?? "";
    message.accountNumber = object.accountNumber ?? "";
    message.status = object.status ?? CustomerStatus.CUSTOMER_STATUS_UNSPECIFIED;
    return message;
  },
};

function createBaseUser(): User {
  return { name: "", email: "", givenName: "", familyName: "" };
}

export const User = {
  encode(message: User, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.email !== "") {
      writer.uint32(18).string(message.email);
    }
    if (message.givenName !== "") {
      writer.uint32(26).string(message.givenName);
    }
    if (message.familyName !== "") {
      writer.uint32(34).string(message.familyName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): User {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUser();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.email = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.givenName = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.familyName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): User {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      email: isSet(object.email) ? globalThis.String(object.email) : "",
      givenName: isSet(object.givenName) ? globalThis.String(object.givenName) : "",
      familyName: isSet(object.familyName) ? globalThis.String(object.familyName) : "",
    };
  },

  toJSON(message: User): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.email !== "") {
      obj.email = message.email;
    }
    if (message.givenName !== "") {
      obj.givenName = message.givenName;
    }
    if (message.familyName !== "") {
      obj.familyName = message.familyName;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<User>, I>>(base?: I): User {
    return User.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<User>, I>>(object: I): User {
    const message = createBaseUser();
    message.name = object.name ?? "";
    message.email = object.email ?? "";
    message.givenName = object.givenName ?? "";
    message.familyName = object.familyName ?? "";
    return message;
  },
};

function createBaseGetTenantTemplatesRequest(): GetTenantTemplatesRequest {
  return {};
}

export const GetTenantTemplatesRequest = {
  encode(_: GetTenantTemplatesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetTenantTemplatesRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetTenantTemplatesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): GetTenantTemplatesRequest {
    return {};
  },

  toJSON(_: GetTenantTemplatesRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<GetTenantTemplatesRequest>, I>>(base?: I): GetTenantTemplatesRequest {
    return GetTenantTemplatesRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetTenantTemplatesRequest>, I>>(_: I): GetTenantTemplatesRequest {
    const message = createBaseGetTenantTemplatesRequest();
    return message;
  },
};

function createBaseGetTenantTemplatesResponse(): GetTenantTemplatesResponse {
  return { tenantTemplates: [] };
}

export const GetTenantTemplatesResponse = {
  encode(message: GetTenantTemplatesResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.tenantTemplates) {
      TenantTemplate.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetTenantTemplatesResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetTenantTemplatesResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tenantTemplates.push(TenantTemplate.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetTenantTemplatesResponse {
    return {
      tenantTemplates: globalThis.Array.isArray(object?.tenantTemplates)
        ? object.tenantTemplates.map((e: any) => TenantTemplate.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GetTenantTemplatesResponse): unknown {
    const obj: any = {};
    if (message.tenantTemplates?.length) {
      obj.tenantTemplates = message.tenantTemplates.map((e) => TenantTemplate.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetTenantTemplatesResponse>, I>>(base?: I): GetTenantTemplatesResponse {
    return GetTenantTemplatesResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetTenantTemplatesResponse>, I>>(object: I): GetTenantTemplatesResponse {
    const message = createBaseGetTenantTemplatesResponse();
    message.tenantTemplates = object.tenantTemplates?.map((e) => TenantTemplate.fromPartial(e)) || [];
    return message;
  },
};

function createBaseTenantTemplate(): TenantTemplate {
  return { id: "", name: "", configuration: "", description: "", golden: false, archived: false };
}

export const TenantTemplate = {
  encode(message: TenantTemplate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.configuration !== "") {
      writer.uint32(26).string(message.configuration);
    }
    if (message.description !== "") {
      writer.uint32(34).string(message.description);
    }
    if (message.golden !== false) {
      writer.uint32(40).bool(message.golden);
    }
    if (message.archived !== false) {
      writer.uint32(48).bool(message.archived);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TenantTemplate {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTenantTemplate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.configuration = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.description = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.golden = reader.bool();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.archived = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TenantTemplate {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      configuration: isSet(object.configuration) ? globalThis.String(object.configuration) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      golden: isSet(object.golden) ? globalThis.Boolean(object.golden) : false,
      archived: isSet(object.archived) ? globalThis.Boolean(object.archived) : false,
    };
  },

  toJSON(message: TenantTemplate): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.configuration !== "") {
      obj.configuration = message.configuration;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.golden !== false) {
      obj.golden = message.golden;
    }
    if (message.archived !== false) {
      obj.archived = message.archived;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TenantTemplate>, I>>(base?: I): TenantTemplate {
    return TenantTemplate.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TenantTemplate>, I>>(object: I): TenantTemplate {
    const message = createBaseTenantTemplate();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.configuration = object.configuration ?? "";
    message.description = object.description ?? "";
    message.golden = object.golden ?? false;
    message.archived = object.archived ?? false;
    return message;
  },
};

function createBaseCreateTenantFromTemplateRequest(): CreateTenantFromTemplateRequest {
  return {
    customerId: "",
    templateId: "",
    tenantName: "",
    tenantSubdomain: "",
    firstUser: undefined,
    enableTendoEmployeeAccess: false,
    isProduction: false,
    includeObjectData: false,
    overrideObjectDataIds: [],
    dbCluster: "",
    provisionDemoUsers: false,
    demoUserBaseEmail: "",
    provisionDemoUserAuth0Accounts: false,
  };
}

export const CreateTenantFromTemplateRequest = {
  encode(message: CreateTenantFromTemplateRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.customerId !== "") {
      writer.uint32(10).string(message.customerId);
    }
    if (message.templateId !== "") {
      writer.uint32(18).string(message.templateId);
    }
    if (message.tenantName !== "") {
      writer.uint32(26).string(message.tenantName);
    }
    if (message.tenantSubdomain !== "") {
      writer.uint32(34).string(message.tenantSubdomain);
    }
    if (message.firstUser !== undefined) {
      User.encode(message.firstUser, writer.uint32(42).fork()).ldelim();
    }
    if (message.enableTendoEmployeeAccess !== false) {
      writer.uint32(48).bool(message.enableTendoEmployeeAccess);
    }
    if (message.isProduction !== false) {
      writer.uint32(56).bool(message.isProduction);
    }
    if (message.includeObjectData !== false) {
      writer.uint32(64).bool(message.includeObjectData);
    }
    for (const v of message.overrideObjectDataIds) {
      writer.uint32(74).string(v!);
    }
    if (message.dbCluster !== "") {
      writer.uint32(82).string(message.dbCluster);
    }
    if (message.provisionDemoUsers !== false) {
      writer.uint32(88).bool(message.provisionDemoUsers);
    }
    if (message.demoUserBaseEmail !== "") {
      writer.uint32(98).string(message.demoUserBaseEmail);
    }
    if (message.provisionDemoUserAuth0Accounts !== false) {
      writer.uint32(104).bool(message.provisionDemoUserAuth0Accounts);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateTenantFromTemplateRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateTenantFromTemplateRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.customerId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.templateId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.tenantName = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.tenantSubdomain = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.firstUser = User.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.enableTendoEmployeeAccess = reader.bool();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.isProduction = reader.bool();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.includeObjectData = reader.bool();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.overrideObjectDataIds.push(reader.string());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.dbCluster = reader.string();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.provisionDemoUsers = reader.bool();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.demoUserBaseEmail = reader.string();
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.provisionDemoUserAuth0Accounts = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateTenantFromTemplateRequest {
    return {
      customerId: isSet(object.customerId) ? globalThis.String(object.customerId) : "",
      templateId: isSet(object.templateId) ? globalThis.String(object.templateId) : "",
      tenantName: isSet(object.tenantName) ? globalThis.String(object.tenantName) : "",
      tenantSubdomain: isSet(object.tenantSubdomain) ? globalThis.String(object.tenantSubdomain) : "",
      firstUser: isSet(object.firstUser) ? User.fromJSON(object.firstUser) : undefined,
      enableTendoEmployeeAccess: isSet(object.enableTendoEmployeeAccess)
        ? globalThis.Boolean(object.enableTendoEmployeeAccess)
        : false,
      isProduction: isSet(object.isProduction) ? globalThis.Boolean(object.isProduction) : false,
      includeObjectData: isSet(object.includeObjectData) ? globalThis.Boolean(object.includeObjectData) : false,
      overrideObjectDataIds: globalThis.Array.isArray(object?.overrideObjectDataIds)
        ? object.overrideObjectDataIds.map((e: any) => globalThis.String(e))
        : [],
      dbCluster: isSet(object.dbCluster) ? globalThis.String(object.dbCluster) : "",
      provisionDemoUsers: isSet(object.provisionDemoUsers) ? globalThis.Boolean(object.provisionDemoUsers) : false,
      demoUserBaseEmail: isSet(object.demoUserBaseEmail) ? globalThis.String(object.demoUserBaseEmail) : "",
      provisionDemoUserAuth0Accounts: isSet(object.provisionDemoUserAuth0Accounts)
        ? globalThis.Boolean(object.provisionDemoUserAuth0Accounts)
        : false,
    };
  },

  toJSON(message: CreateTenantFromTemplateRequest): unknown {
    const obj: any = {};
    if (message.customerId !== "") {
      obj.customerId = message.customerId;
    }
    if (message.templateId !== "") {
      obj.templateId = message.templateId;
    }
    if (message.tenantName !== "") {
      obj.tenantName = message.tenantName;
    }
    if (message.tenantSubdomain !== "") {
      obj.tenantSubdomain = message.tenantSubdomain;
    }
    if (message.firstUser !== undefined) {
      obj.firstUser = User.toJSON(message.firstUser);
    }
    if (message.enableTendoEmployeeAccess !== false) {
      obj.enableTendoEmployeeAccess = message.enableTendoEmployeeAccess;
    }
    if (message.isProduction !== false) {
      obj.isProduction = message.isProduction;
    }
    if (message.includeObjectData !== false) {
      obj.includeObjectData = message.includeObjectData;
    }
    if (message.overrideObjectDataIds?.length) {
      obj.overrideObjectDataIds = message.overrideObjectDataIds;
    }
    if (message.dbCluster !== "") {
      obj.dbCluster = message.dbCluster;
    }
    if (message.provisionDemoUsers !== false) {
      obj.provisionDemoUsers = message.provisionDemoUsers;
    }
    if (message.demoUserBaseEmail !== "") {
      obj.demoUserBaseEmail = message.demoUserBaseEmail;
    }
    if (message.provisionDemoUserAuth0Accounts !== false) {
      obj.provisionDemoUserAuth0Accounts = message.provisionDemoUserAuth0Accounts;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateTenantFromTemplateRequest>, I>>(base?: I): CreateTenantFromTemplateRequest {
    return CreateTenantFromTemplateRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateTenantFromTemplateRequest>, I>>(
    object: I,
  ): CreateTenantFromTemplateRequest {
    const message = createBaseCreateTenantFromTemplateRequest();
    message.customerId = object.customerId ?? "";
    message.templateId = object.templateId ?? "";
    message.tenantName = object.tenantName ?? "";
    message.tenantSubdomain = object.tenantSubdomain ?? "";
    message.firstUser = (object.firstUser !== undefined && object.firstUser !== null)
      ? User.fromPartial(object.firstUser)
      : undefined;
    message.enableTendoEmployeeAccess = object.enableTendoEmployeeAccess ?? false;
    message.isProduction = object.isProduction ?? false;
    message.includeObjectData = object.includeObjectData ?? false;
    message.overrideObjectDataIds = object.overrideObjectDataIds?.map((e) => e) || [];
    message.dbCluster = object.dbCluster ?? "";
    message.provisionDemoUsers = object.provisionDemoUsers ?? false;
    message.demoUserBaseEmail = object.demoUserBaseEmail ?? "";
    message.provisionDemoUserAuth0Accounts = object.provisionDemoUserAuth0Accounts ?? false;
    return message;
  },
};

function createBaseCreateTenantFromTemplateResponse(): CreateTenantFromTemplateResponse {
  return { tenant: undefined };
}

export const CreateTenantFromTemplateResponse = {
  encode(message: CreateTenantFromTemplateResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tenant !== undefined) {
      Tenant.encode(message.tenant, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateTenantFromTemplateResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateTenantFromTemplateResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tenant = Tenant.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateTenantFromTemplateResponse {
    return { tenant: isSet(object.tenant) ? Tenant.fromJSON(object.tenant) : undefined };
  },

  toJSON(message: CreateTenantFromTemplateResponse): unknown {
    const obj: any = {};
    if (message.tenant !== undefined) {
      obj.tenant = Tenant.toJSON(message.tenant);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateTenantFromTemplateResponse>, I>>(
    base?: I,
  ): CreateTenantFromTemplateResponse {
    return CreateTenantFromTemplateResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateTenantFromTemplateResponse>, I>>(
    object: I,
  ): CreateTenantFromTemplateResponse {
    const message = createBaseCreateTenantFromTemplateResponse();
    message.tenant = (object.tenant !== undefined && object.tenant !== null)
      ? Tenant.fromPartial(object.tenant)
      : undefined;
    return message;
  },
};

function createBaseDestroyTenantRequest(): DestroyTenantRequest {
  return { tenantId: "" };
}

export const DestroyTenantRequest = {
  encode(message: DestroyTenantRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tenantId !== "") {
      writer.uint32(10).string(message.tenantId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DestroyTenantRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDestroyTenantRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tenantId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DestroyTenantRequest {
    return { tenantId: isSet(object.tenantId) ? globalThis.String(object.tenantId) : "" };
  },

  toJSON(message: DestroyTenantRequest): unknown {
    const obj: any = {};
    if (message.tenantId !== "") {
      obj.tenantId = message.tenantId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DestroyTenantRequest>, I>>(base?: I): DestroyTenantRequest {
    return DestroyTenantRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DestroyTenantRequest>, I>>(object: I): DestroyTenantRequest {
    const message = createBaseDestroyTenantRequest();
    message.tenantId = object.tenantId ?? "";
    return message;
  },
};

function createBaseDestroyTenantResponse(): DestroyTenantResponse {
  return { tenant: undefined };
}

export const DestroyTenantResponse = {
  encode(message: DestroyTenantResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tenant !== undefined) {
      Tenant.encode(message.tenant, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DestroyTenantResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDestroyTenantResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tenant = Tenant.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DestroyTenantResponse {
    return { tenant: isSet(object.tenant) ? Tenant.fromJSON(object.tenant) : undefined };
  },

  toJSON(message: DestroyTenantResponse): unknown {
    const obj: any = {};
    if (message.tenant !== undefined) {
      obj.tenant = Tenant.toJSON(message.tenant);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DestroyTenantResponse>, I>>(base?: I): DestroyTenantResponse {
    return DestroyTenantResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DestroyTenantResponse>, I>>(object: I): DestroyTenantResponse {
    const message = createBaseDestroyTenantResponse();
    message.tenant = (object.tenant !== undefined && object.tenant !== null)
      ? Tenant.fromPartial(object.tenant)
      : undefined;
    return message;
  },
};

function createBaseDeleteTenantRequest(): DeleteTenantRequest {
  return { tenantId: "", force: false };
}

export const DeleteTenantRequest = {
  encode(message: DeleteTenantRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tenantId !== "") {
      writer.uint32(10).string(message.tenantId);
    }
    if (message.force !== false) {
      writer.uint32(16).bool(message.force);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteTenantRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteTenantRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tenantId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.force = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteTenantRequest {
    return {
      tenantId: isSet(object.tenantId) ? globalThis.String(object.tenantId) : "",
      force: isSet(object.force) ? globalThis.Boolean(object.force) : false,
    };
  },

  toJSON(message: DeleteTenantRequest): unknown {
    const obj: any = {};
    if (message.tenantId !== "") {
      obj.tenantId = message.tenantId;
    }
    if (message.force !== false) {
      obj.force = message.force;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeleteTenantRequest>, I>>(base?: I): DeleteTenantRequest {
    return DeleteTenantRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteTenantRequest>, I>>(object: I): DeleteTenantRequest {
    const message = createBaseDeleteTenantRequest();
    message.tenantId = object.tenantId ?? "";
    message.force = object.force ?? false;
    return message;
  },
};

function createBaseDeleteTenantResponse(): DeleteTenantResponse {
  return { tenant: undefined };
}

export const DeleteTenantResponse = {
  encode(message: DeleteTenantResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tenant !== undefined) {
      Tenant.encode(message.tenant, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteTenantResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteTenantResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tenant = Tenant.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteTenantResponse {
    return { tenant: isSet(object.tenant) ? Tenant.fromJSON(object.tenant) : undefined };
  },

  toJSON(message: DeleteTenantResponse): unknown {
    const obj: any = {};
    if (message.tenant !== undefined) {
      obj.tenant = Tenant.toJSON(message.tenant);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeleteTenantResponse>, I>>(base?: I): DeleteTenantResponse {
    return DeleteTenantResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteTenantResponse>, I>>(object: I): DeleteTenantResponse {
    const message = createBaseDeleteTenantResponse();
    message.tenant = (object.tenant !== undefined && object.tenant !== null)
      ? Tenant.fromPartial(object.tenant)
      : undefined;
    return message;
  },
};

function createBaseInitiateTenantExportRequest(): InitiateTenantExportRequest {
  return {
    tenantId: "",
    templateName: "",
    templateDescription: "",
    includeObjectData: false,
    excludedObjectDefinitions: [],
  };
}

export const InitiateTenantExportRequest = {
  encode(message: InitiateTenantExportRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tenantId !== "") {
      writer.uint32(10).string(message.tenantId);
    }
    if (message.templateName !== "") {
      writer.uint32(18).string(message.templateName);
    }
    if (message.templateDescription !== "") {
      writer.uint32(26).string(message.templateDescription);
    }
    if (message.includeObjectData !== false) {
      writer.uint32(32).bool(message.includeObjectData);
    }
    for (const v of message.excludedObjectDefinitions) {
      writer.uint32(42).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InitiateTenantExportRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInitiateTenantExportRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tenantId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.templateName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.templateDescription = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.includeObjectData = reader.bool();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.excludedObjectDefinitions.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): InitiateTenantExportRequest {
    return {
      tenantId: isSet(object.tenantId) ? globalThis.String(object.tenantId) : "",
      templateName: isSet(object.templateName) ? globalThis.String(object.templateName) : "",
      templateDescription: isSet(object.templateDescription) ? globalThis.String(object.templateDescription) : "",
      includeObjectData: isSet(object.includeObjectData) ? globalThis.Boolean(object.includeObjectData) : false,
      excludedObjectDefinitions: globalThis.Array.isArray(object?.excludedObjectDefinitions)
        ? object.excludedObjectDefinitions.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: InitiateTenantExportRequest): unknown {
    const obj: any = {};
    if (message.tenantId !== "") {
      obj.tenantId = message.tenantId;
    }
    if (message.templateName !== "") {
      obj.templateName = message.templateName;
    }
    if (message.templateDescription !== "") {
      obj.templateDescription = message.templateDescription;
    }
    if (message.includeObjectData !== false) {
      obj.includeObjectData = message.includeObjectData;
    }
    if (message.excludedObjectDefinitions?.length) {
      obj.excludedObjectDefinitions = message.excludedObjectDefinitions;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<InitiateTenantExportRequest>, I>>(base?: I): InitiateTenantExportRequest {
    return InitiateTenantExportRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<InitiateTenantExportRequest>, I>>(object: I): InitiateTenantExportRequest {
    const message = createBaseInitiateTenantExportRequest();
    message.tenantId = object.tenantId ?? "";
    message.templateName = object.templateName ?? "";
    message.templateDescription = object.templateDescription ?? "";
    message.includeObjectData = object.includeObjectData ?? false;
    message.excludedObjectDefinitions = object.excludedObjectDefinitions?.map((e) => e) || [];
    return message;
  },
};

function createBaseInitiateTenantExportResponse(): InitiateTenantExportResponse {
  return { templateId: "" };
}

export const InitiateTenantExportResponse = {
  encode(message: InitiateTenantExportResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.templateId !== "") {
      writer.uint32(10).string(message.templateId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InitiateTenantExportResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInitiateTenantExportResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.templateId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): InitiateTenantExportResponse {
    return { templateId: isSet(object.templateId) ? globalThis.String(object.templateId) : "" };
  },

  toJSON(message: InitiateTenantExportResponse): unknown {
    const obj: any = {};
    if (message.templateId !== "") {
      obj.templateId = message.templateId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<InitiateTenantExportResponse>, I>>(base?: I): InitiateTenantExportResponse {
    return InitiateTenantExportResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<InitiateTenantExportResponse>, I>>(object: I): InitiateTenantExportResponse {
    const message = createBaseInitiateTenantExportResponse();
    message.templateId = object.templateId ?? "";
    return message;
  },
};

function createBaseGetTenantDestructionsRequest(): GetTenantDestructionsRequest {
  return { tenantId: "" };
}

export const GetTenantDestructionsRequest = {
  encode(message: GetTenantDestructionsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tenantId !== "") {
      writer.uint32(10).string(message.tenantId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetTenantDestructionsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetTenantDestructionsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tenantId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetTenantDestructionsRequest {
    return { tenantId: isSet(object.tenantId) ? globalThis.String(object.tenantId) : "" };
  },

  toJSON(message: GetTenantDestructionsRequest): unknown {
    const obj: any = {};
    if (message.tenantId !== "") {
      obj.tenantId = message.tenantId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetTenantDestructionsRequest>, I>>(base?: I): GetTenantDestructionsRequest {
    return GetTenantDestructionsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetTenantDestructionsRequest>, I>>(object: I): GetTenantDestructionsRequest {
    const message = createBaseGetTenantDestructionsRequest();
    message.tenantId = object.tenantId ?? "";
    return message;
  },
};

function createBaseGetTenantDestructionsResponse(): GetTenantDestructionsResponse {
  return { tenantDestructions: [] };
}

export const GetTenantDestructionsResponse = {
  encode(message: GetTenantDestructionsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.tenantDestructions) {
      TenantDestruction.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetTenantDestructionsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetTenantDestructionsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tenantDestructions.push(TenantDestruction.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetTenantDestructionsResponse {
    return {
      tenantDestructions: globalThis.Array.isArray(object?.tenantDestructions)
        ? object.tenantDestructions.map((e: any) => TenantDestruction.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GetTenantDestructionsResponse): unknown {
    const obj: any = {};
    if (message.tenantDestructions?.length) {
      obj.tenantDestructions = message.tenantDestructions.map((e) => TenantDestruction.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetTenantDestructionsResponse>, I>>(base?: I): GetTenantDestructionsResponse {
    return GetTenantDestructionsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetTenantDestructionsResponse>, I>>(
    object: I,
  ): GetTenantDestructionsResponse {
    const message = createBaseGetTenantDestructionsResponse();
    message.tenantDestructions = object.tenantDestructions?.map((e) => TenantDestruction.fromPartial(e)) || [];
    return message;
  },
};

function createBaseTenantDestruction(): TenantDestruction {
  return { service: "", status: "", errorMessage: "", name: "" };
}

export const TenantDestruction = {
  encode(message: TenantDestruction, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.service !== "") {
      writer.uint32(10).string(message.service);
    }
    if (message.status !== "") {
      writer.uint32(18).string(message.status);
    }
    if (message.errorMessage !== "") {
      writer.uint32(26).string(message.errorMessage);
    }
    if (message.name !== "") {
      writer.uint32(34).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TenantDestruction {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTenantDestruction();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.service = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.status = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.errorMessage = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TenantDestruction {
    return {
      service: isSet(object.service) ? globalThis.String(object.service) : "",
      status: isSet(object.status) ? globalThis.String(object.status) : "",
      errorMessage: isSet(object.errorMessage) ? globalThis.String(object.errorMessage) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
    };
  },

  toJSON(message: TenantDestruction): unknown {
    const obj: any = {};
    if (message.service !== "") {
      obj.service = message.service;
    }
    if (message.status !== "") {
      obj.status = message.status;
    }
    if (message.errorMessage !== "") {
      obj.errorMessage = message.errorMessage;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TenantDestruction>, I>>(base?: I): TenantDestruction {
    return TenantDestruction.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TenantDestruction>, I>>(object: I): TenantDestruction {
    const message = createBaseTenantDestruction();
    message.service = object.service ?? "";
    message.status = object.status ?? "";
    message.errorMessage = object.errorMessage ?? "";
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseGetTemplateFragmentDeploymentsRequest(): GetTemplateFragmentDeploymentsRequest {
  return { tenantId: "" };
}

export const GetTemplateFragmentDeploymentsRequest = {
  encode(message: GetTemplateFragmentDeploymentsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tenantId !== "") {
      writer.uint32(10).string(message.tenantId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetTemplateFragmentDeploymentsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetTemplateFragmentDeploymentsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tenantId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetTemplateFragmentDeploymentsRequest {
    return { tenantId: isSet(object.tenantId) ? globalThis.String(object.tenantId) : "" };
  },

  toJSON(message: GetTemplateFragmentDeploymentsRequest): unknown {
    const obj: any = {};
    if (message.tenantId !== "") {
      obj.tenantId = message.tenantId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetTemplateFragmentDeploymentsRequest>, I>>(
    base?: I,
  ): GetTemplateFragmentDeploymentsRequest {
    return GetTemplateFragmentDeploymentsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetTemplateFragmentDeploymentsRequest>, I>>(
    object: I,
  ): GetTemplateFragmentDeploymentsRequest {
    const message = createBaseGetTemplateFragmentDeploymentsRequest();
    message.tenantId = object.tenantId ?? "";
    return message;
  },
};

function createBaseGetTemplateFragmentDeploymentsResponse(): GetTemplateFragmentDeploymentsResponse {
  return { templateFragmentDeployments: [] };
}

export const GetTemplateFragmentDeploymentsResponse = {
  encode(message: GetTemplateFragmentDeploymentsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.templateFragmentDeployments) {
      TemplateFragmentDeployment.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetTemplateFragmentDeploymentsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetTemplateFragmentDeploymentsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.templateFragmentDeployments.push(TemplateFragmentDeployment.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetTemplateFragmentDeploymentsResponse {
    return {
      templateFragmentDeployments: globalThis.Array.isArray(object?.templateFragmentDeployments)
        ? object.templateFragmentDeployments.map((e: any) => TemplateFragmentDeployment.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GetTemplateFragmentDeploymentsResponse): unknown {
    const obj: any = {};
    if (message.templateFragmentDeployments?.length) {
      obj.templateFragmentDeployments = message.templateFragmentDeployments.map((e) =>
        TemplateFragmentDeployment.toJSON(e)
      );
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetTemplateFragmentDeploymentsResponse>, I>>(
    base?: I,
  ): GetTemplateFragmentDeploymentsResponse {
    return GetTemplateFragmentDeploymentsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetTemplateFragmentDeploymentsResponse>, I>>(
    object: I,
  ): GetTemplateFragmentDeploymentsResponse {
    const message = createBaseGetTemplateFragmentDeploymentsResponse();
    message.templateFragmentDeployments =
      object.templateFragmentDeployments?.map((e) => TemplateFragmentDeployment.fromPartial(e)) || [];
    return message;
  },
};

function createBaseTemplateFragmentDeployment(): TemplateFragmentDeployment {
  return { service: "", name: "", status: "", errorMessage: "" };
}

export const TemplateFragmentDeployment = {
  encode(message: TemplateFragmentDeployment, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.service !== "") {
      writer.uint32(10).string(message.service);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.status !== "") {
      writer.uint32(26).string(message.status);
    }
    if (message.errorMessage !== "") {
      writer.uint32(34).string(message.errorMessage);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TemplateFragmentDeployment {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTemplateFragmentDeployment();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.service = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.status = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.errorMessage = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TemplateFragmentDeployment {
    return {
      service: isSet(object.service) ? globalThis.String(object.service) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      status: isSet(object.status) ? globalThis.String(object.status) : "",
      errorMessage: isSet(object.errorMessage) ? globalThis.String(object.errorMessage) : "",
    };
  },

  toJSON(message: TemplateFragmentDeployment): unknown {
    const obj: any = {};
    if (message.service !== "") {
      obj.service = message.service;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.status !== "") {
      obj.status = message.status;
    }
    if (message.errorMessage !== "") {
      obj.errorMessage = message.errorMessage;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TemplateFragmentDeployment>, I>>(base?: I): TemplateFragmentDeployment {
    return TemplateFragmentDeployment.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TemplateFragmentDeployment>, I>>(object: I): TemplateFragmentDeployment {
    const message = createBaseTemplateFragmentDeployment();
    message.service = object.service ?? "";
    message.name = object.name ?? "";
    message.status = object.status ?? "";
    message.errorMessage = object.errorMessage ?? "";
    return message;
  },
};

function createBaseGetRenderedTemplateRequest(): GetRenderedTemplateRequest {
  return { templateId: "" };
}

export const GetRenderedTemplateRequest = {
  encode(message: GetRenderedTemplateRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.templateId !== "") {
      writer.uint32(10).string(message.templateId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetRenderedTemplateRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetRenderedTemplateRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.templateId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetRenderedTemplateRequest {
    return { templateId: isSet(object.templateId) ? globalThis.String(object.templateId) : "" };
  },

  toJSON(message: GetRenderedTemplateRequest): unknown {
    const obj: any = {};
    if (message.templateId !== "") {
      obj.templateId = message.templateId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetRenderedTemplateRequest>, I>>(base?: I): GetRenderedTemplateRequest {
    return GetRenderedTemplateRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetRenderedTemplateRequest>, I>>(object: I): GetRenderedTemplateRequest {
    const message = createBaseGetRenderedTemplateRequest();
    message.templateId = object.templateId ?? "";
    return message;
  },
};

function createBaseGetRenderedTemplateResponse(): GetRenderedTemplateResponse {
  return { templateData: new Uint8Array(0) };
}

export const GetRenderedTemplateResponse = {
  encode(message: GetRenderedTemplateResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.templateData.length !== 0) {
      writer.uint32(10).bytes(message.templateData);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetRenderedTemplateResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetRenderedTemplateResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.templateData = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetRenderedTemplateResponse {
    return { templateData: isSet(object.templateData) ? bytesFromBase64(object.templateData) : new Uint8Array(0) };
  },

  toJSON(message: GetRenderedTemplateResponse): unknown {
    const obj: any = {};
    if (message.templateData.length !== 0) {
      obj.templateData = base64FromBytes(message.templateData);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetRenderedTemplateResponse>, I>>(base?: I): GetRenderedTemplateResponse {
    return GetRenderedTemplateResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetRenderedTemplateResponse>, I>>(object: I): GetRenderedTemplateResponse {
    const message = createBaseGetRenderedTemplateResponse();
    message.templateData = object.templateData ?? new Uint8Array(0);
    return message;
  },
};

function createBaseGetRenderedTemplateFragmentRequest(): GetRenderedTemplateFragmentRequest {
  return { templateFragmentId: "" };
}

export const GetRenderedTemplateFragmentRequest = {
  encode(message: GetRenderedTemplateFragmentRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.templateFragmentId !== "") {
      writer.uint32(10).string(message.templateFragmentId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetRenderedTemplateFragmentRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetRenderedTemplateFragmentRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.templateFragmentId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetRenderedTemplateFragmentRequest {
    return { templateFragmentId: isSet(object.templateFragmentId) ? globalThis.String(object.templateFragmentId) : "" };
  },

  toJSON(message: GetRenderedTemplateFragmentRequest): unknown {
    const obj: any = {};
    if (message.templateFragmentId !== "") {
      obj.templateFragmentId = message.templateFragmentId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetRenderedTemplateFragmentRequest>, I>>(
    base?: I,
  ): GetRenderedTemplateFragmentRequest {
    return GetRenderedTemplateFragmentRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetRenderedTemplateFragmentRequest>, I>>(
    object: I,
  ): GetRenderedTemplateFragmentRequest {
    const message = createBaseGetRenderedTemplateFragmentRequest();
    message.templateFragmentId = object.templateFragmentId ?? "";
    return message;
  },
};

function createBaseGetRenderedTemplateFragmentResponse(): GetRenderedTemplateFragmentResponse {
  return { templateFragmentData: new Uint8Array(0) };
}

export const GetRenderedTemplateFragmentResponse = {
  encode(message: GetRenderedTemplateFragmentResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.templateFragmentData.length !== 0) {
      writer.uint32(10).bytes(message.templateFragmentData);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetRenderedTemplateFragmentResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetRenderedTemplateFragmentResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.templateFragmentData = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetRenderedTemplateFragmentResponse {
    return {
      templateFragmentData: isSet(object.templateFragmentData)
        ? bytesFromBase64(object.templateFragmentData)
        : new Uint8Array(0),
    };
  },

  toJSON(message: GetRenderedTemplateFragmentResponse): unknown {
    const obj: any = {};
    if (message.templateFragmentData.length !== 0) {
      obj.templateFragmentData = base64FromBytes(message.templateFragmentData);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetRenderedTemplateFragmentResponse>, I>>(
    base?: I,
  ): GetRenderedTemplateFragmentResponse {
    return GetRenderedTemplateFragmentResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetRenderedTemplateFragmentResponse>, I>>(
    object: I,
  ): GetRenderedTemplateFragmentResponse {
    const message = createBaseGetRenderedTemplateFragmentResponse();
    message.templateFragmentData = object.templateFragmentData ?? new Uint8Array(0);
    return message;
  },
};

function createBaseUploadTemplateRequest(): UploadTemplateRequest {
  return { templateData: new Uint8Array(0) };
}

export const UploadTemplateRequest = {
  encode(message: UploadTemplateRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.templateData.length !== 0) {
      writer.uint32(10).bytes(message.templateData);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UploadTemplateRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUploadTemplateRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.templateData = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UploadTemplateRequest {
    return { templateData: isSet(object.templateData) ? bytesFromBase64(object.templateData) : new Uint8Array(0) };
  },

  toJSON(message: UploadTemplateRequest): unknown {
    const obj: any = {};
    if (message.templateData.length !== 0) {
      obj.templateData = base64FromBytes(message.templateData);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UploadTemplateRequest>, I>>(base?: I): UploadTemplateRequest {
    return UploadTemplateRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UploadTemplateRequest>, I>>(object: I): UploadTemplateRequest {
    const message = createBaseUploadTemplateRequest();
    message.templateData = object.templateData ?? new Uint8Array(0);
    return message;
  },
};

function createBaseUploadTemplateResponse(): UploadTemplateResponse {
  return { templateId: "" };
}

export const UploadTemplateResponse = {
  encode(message: UploadTemplateResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.templateId !== "") {
      writer.uint32(10).string(message.templateId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UploadTemplateResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUploadTemplateResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.templateId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UploadTemplateResponse {
    return { templateId: isSet(object.templateId) ? globalThis.String(object.templateId) : "" };
  },

  toJSON(message: UploadTemplateResponse): unknown {
    const obj: any = {};
    if (message.templateId !== "") {
      obj.templateId = message.templateId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UploadTemplateResponse>, I>>(base?: I): UploadTemplateResponse {
    return UploadTemplateResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UploadTemplateResponse>, I>>(object: I): UploadTemplateResponse {
    const message = createBaseUploadTemplateResponse();
    message.templateId = object.templateId ?? "";
    return message;
  },
};

function createBaseGetTemplatesRequest(): GetTemplatesRequest {
  return { deployable: false };
}

export const GetTemplatesRequest = {
  encode(message: GetTemplatesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.deployable !== false) {
      writer.uint32(8).bool(message.deployable);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetTemplatesRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetTemplatesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.deployable = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetTemplatesRequest {
    return { deployable: isSet(object.deployable) ? globalThis.Boolean(object.deployable) : false };
  },

  toJSON(message: GetTemplatesRequest): unknown {
    const obj: any = {};
    if (message.deployable !== false) {
      obj.deployable = message.deployable;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetTemplatesRequest>, I>>(base?: I): GetTemplatesRequest {
    return GetTemplatesRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetTemplatesRequest>, I>>(object: I): GetTemplatesRequest {
    const message = createBaseGetTemplatesRequest();
    message.deployable = object.deployable ?? false;
    return message;
  },
};

function createBaseGetTemplatesResponse(): GetTemplatesResponse {
  return { templates: [] };
}

export const GetTemplatesResponse = {
  encode(message: GetTemplatesResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.templates) {
      Template.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetTemplatesResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetTemplatesResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.templates.push(Template.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetTemplatesResponse {
    return {
      templates: globalThis.Array.isArray(object?.templates)
        ? object.templates.map((e: any) => Template.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GetTemplatesResponse): unknown {
    const obj: any = {};
    if (message.templates?.length) {
      obj.templates = message.templates.map((e) => Template.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetTemplatesResponse>, I>>(base?: I): GetTemplatesResponse {
    return GetTemplatesResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetTemplatesResponse>, I>>(object: I): GetTemplatesResponse {
    const message = createBaseGetTemplatesResponse();
    message.templates = object.templates?.map((e) => Template.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetTemplateRequest(): GetTemplateRequest {
  return { templateId: "" };
}

export const GetTemplateRequest = {
  encode(message: GetTemplateRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.templateId !== "") {
      writer.uint32(10).string(message.templateId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetTemplateRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetTemplateRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.templateId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetTemplateRequest {
    return { templateId: isSet(object.templateId) ? globalThis.String(object.templateId) : "" };
  },

  toJSON(message: GetTemplateRequest): unknown {
    const obj: any = {};
    if (message.templateId !== "") {
      obj.templateId = message.templateId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetTemplateRequest>, I>>(base?: I): GetTemplateRequest {
    return GetTemplateRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetTemplateRequest>, I>>(object: I): GetTemplateRequest {
    const message = createBaseGetTemplateRequest();
    message.templateId = object.templateId ?? "";
    return message;
  },
};

function createBaseGetTemplateResponse(): GetTemplateResponse {
  return { template: undefined };
}

export const GetTemplateResponse = {
  encode(message: GetTemplateResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.template !== undefined) {
      Template.encode(message.template, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetTemplateResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetTemplateResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.template = Template.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetTemplateResponse {
    return { template: isSet(object.template) ? Template.fromJSON(object.template) : undefined };
  },

  toJSON(message: GetTemplateResponse): unknown {
    const obj: any = {};
    if (message.template !== undefined) {
      obj.template = Template.toJSON(message.template);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetTemplateResponse>, I>>(base?: I): GetTemplateResponse {
    return GetTemplateResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetTemplateResponse>, I>>(object: I): GetTemplateResponse {
    const message = createBaseGetTemplateResponse();
    message.template = (object.template !== undefined && object.template !== null)
      ? Template.fromPartial(object.template)
      : undefined;
    return message;
  },
};

function createBaseGetTemplateCreationStatusRequest(): GetTemplateCreationStatusRequest {
  return { templateId: "" };
}

export const GetTemplateCreationStatusRequest = {
  encode(message: GetTemplateCreationStatusRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.templateId !== "") {
      writer.uint32(10).string(message.templateId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetTemplateCreationStatusRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetTemplateCreationStatusRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.templateId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetTemplateCreationStatusRequest {
    return { templateId: isSet(object.templateId) ? globalThis.String(object.templateId) : "" };
  },

  toJSON(message: GetTemplateCreationStatusRequest): unknown {
    const obj: any = {};
    if (message.templateId !== "") {
      obj.templateId = message.templateId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetTemplateCreationStatusRequest>, I>>(
    base?: I,
  ): GetTemplateCreationStatusRequest {
    return GetTemplateCreationStatusRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetTemplateCreationStatusRequest>, I>>(
    object: I,
  ): GetTemplateCreationStatusRequest {
    const message = createBaseGetTemplateCreationStatusRequest();
    message.templateId = object.templateId ?? "";
    return message;
  },
};

function createBaseGetTemplateCreationStatusResponse(): GetTemplateCreationStatusResponse {
  return { templateId: "", status: "" };
}

export const GetTemplateCreationStatusResponse = {
  encode(message: GetTemplateCreationStatusResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.templateId !== "") {
      writer.uint32(10).string(message.templateId);
    }
    if (message.status !== "") {
      writer.uint32(18).string(message.status);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetTemplateCreationStatusResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetTemplateCreationStatusResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.templateId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.status = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetTemplateCreationStatusResponse {
    return {
      templateId: isSet(object.templateId) ? globalThis.String(object.templateId) : "",
      status: isSet(object.status) ? globalThis.String(object.status) : "",
    };
  },

  toJSON(message: GetTemplateCreationStatusResponse): unknown {
    const obj: any = {};
    if (message.templateId !== "") {
      obj.templateId = message.templateId;
    }
    if (message.status !== "") {
      obj.status = message.status;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetTemplateCreationStatusResponse>, I>>(
    base?: I,
  ): GetTemplateCreationStatusResponse {
    return GetTemplateCreationStatusResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetTemplateCreationStatusResponse>, I>>(
    object: I,
  ): GetTemplateCreationStatusResponse {
    const message = createBaseGetTemplateCreationStatusResponse();
    message.templateId = object.templateId ?? "";
    message.status = object.status ?? "";
    return message;
  },
};

function createBaseTemplate(): Template {
  return {
    id: "",
    name: "",
    description: "",
    templateFragments: [],
    golden: false,
    archived: false,
    published: false,
    createdAt: undefined,
    createdFromTenantId: "",
    includesObjectData: false,
    objectData: [],
    emrless: false,
  };
}

export const Template = {
  encode(message: Template, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.description !== "") {
      writer.uint32(26).string(message.description);
    }
    for (const v of message.templateFragments) {
      TemplateFragment.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    if (message.golden !== false) {
      writer.uint32(40).bool(message.golden);
    }
    if (message.archived !== false) {
      writer.uint32(48).bool(message.archived);
    }
    if (message.published !== false) {
      writer.uint32(56).bool(message.published);
    }
    if (message.createdAt !== undefined) {
      Timestamp.encode(toTimestamp(message.createdAt), writer.uint32(66).fork()).ldelim();
    }
    if (message.createdFromTenantId !== "") {
      writer.uint32(74).string(message.createdFromTenantId);
    }
    if (message.includesObjectData !== false) {
      writer.uint32(80).bool(message.includesObjectData);
    }
    for (const v of message.objectData) {
      ObjectData.encode(v!, writer.uint32(90).fork()).ldelim();
    }
    if (message.emrless !== false) {
      writer.uint32(96).bool(message.emrless);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Template {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTemplate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.templateFragments.push(TemplateFragment.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.golden = reader.bool();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.archived = reader.bool();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.published = reader.bool();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.createdAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.createdFromTenantId = reader.string();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.includesObjectData = reader.bool();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.objectData.push(ObjectData.decode(reader, reader.uint32()));
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.emrless = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Template {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      templateFragments: globalThis.Array.isArray(object?.templateFragments)
        ? object.templateFragments.map((e: any) => TemplateFragment.fromJSON(e))
        : [],
      golden: isSet(object.golden) ? globalThis.Boolean(object.golden) : false,
      archived: isSet(object.archived) ? globalThis.Boolean(object.archived) : false,
      published: isSet(object.published) ? globalThis.Boolean(object.published) : false,
      createdAt: isSet(object.createdAt) ? fromJsonTimestamp(object.createdAt) : undefined,
      createdFromTenantId: isSet(object.createdFromTenantId) ? globalThis.String(object.createdFromTenantId) : "",
      includesObjectData: isSet(object.includesObjectData) ? globalThis.Boolean(object.includesObjectData) : false,
      objectData: globalThis.Array.isArray(object?.objectData)
        ? object.objectData.map((e: any) => ObjectData.fromJSON(e))
        : [],
      emrless: isSet(object.emrless) ? globalThis.Boolean(object.emrless) : false,
    };
  },

  toJSON(message: Template): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.templateFragments?.length) {
      obj.templateFragments = message.templateFragments.map((e) => TemplateFragment.toJSON(e));
    }
    if (message.golden !== false) {
      obj.golden = message.golden;
    }
    if (message.archived !== false) {
      obj.archived = message.archived;
    }
    if (message.published !== false) {
      obj.published = message.published;
    }
    if (message.createdAt !== undefined) {
      obj.createdAt = message.createdAt.toISOString();
    }
    if (message.createdFromTenantId !== "") {
      obj.createdFromTenantId = message.createdFromTenantId;
    }
    if (message.includesObjectData !== false) {
      obj.includesObjectData = message.includesObjectData;
    }
    if (message.objectData?.length) {
      obj.objectData = message.objectData.map((e) => ObjectData.toJSON(e));
    }
    if (message.emrless !== false) {
      obj.emrless = message.emrless;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Template>, I>>(base?: I): Template {
    return Template.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Template>, I>>(object: I): Template {
    const message = createBaseTemplate();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    message.templateFragments = object.templateFragments?.map((e) => TemplateFragment.fromPartial(e)) || [];
    message.golden = object.golden ?? false;
    message.archived = object.archived ?? false;
    message.published = object.published ?? false;
    message.createdAt = object.createdAt ?? undefined;
    message.createdFromTenantId = object.createdFromTenantId ?? "";
    message.includesObjectData = object.includesObjectData ?? false;
    message.objectData = object.objectData?.map((e) => ObjectData.fromPartial(e)) || [];
    message.emrless = object.emrless ?? false;
    return message;
  },
};

function createBaseTemplateFragment(): TemplateFragment {
  return { id: "", name: "", service: "", status: "", errorMessage: "", createdAt: undefined, updatedAt: undefined };
}

export const TemplateFragment = {
  encode(message: TemplateFragment, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.service !== "") {
      writer.uint32(26).string(message.service);
    }
    if (message.status !== "") {
      writer.uint32(34).string(message.status);
    }
    if (message.errorMessage !== "") {
      writer.uint32(42).string(message.errorMessage);
    }
    if (message.createdAt !== undefined) {
      Timestamp.encode(toTimestamp(message.createdAt), writer.uint32(50).fork()).ldelim();
    }
    if (message.updatedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.updatedAt), writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TemplateFragment {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTemplateFragment();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.service = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.status = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.errorMessage = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.createdAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.updatedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TemplateFragment {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      service: isSet(object.service) ? globalThis.String(object.service) : "",
      status: isSet(object.status) ? globalThis.String(object.status) : "",
      errorMessage: isSet(object.errorMessage) ? globalThis.String(object.errorMessage) : "",
      createdAt: isSet(object.createdAt) ? fromJsonTimestamp(object.createdAt) : undefined,
      updatedAt: isSet(object.updatedAt) ? fromJsonTimestamp(object.updatedAt) : undefined,
    };
  },

  toJSON(message: TemplateFragment): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.service !== "") {
      obj.service = message.service;
    }
    if (message.status !== "") {
      obj.status = message.status;
    }
    if (message.errorMessage !== "") {
      obj.errorMessage = message.errorMessage;
    }
    if (message.createdAt !== undefined) {
      obj.createdAt = message.createdAt.toISOString();
    }
    if (message.updatedAt !== undefined) {
      obj.updatedAt = message.updatedAt.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TemplateFragment>, I>>(base?: I): TemplateFragment {
    return TemplateFragment.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TemplateFragment>, I>>(object: I): TemplateFragment {
    const message = createBaseTemplateFragment();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.service = object.service ?? "";
    message.status = object.status ?? "";
    message.errorMessage = object.errorMessage ?? "";
    message.createdAt = object.createdAt ?? undefined;
    message.updatedAt = object.updatedAt ?? undefined;
    return message;
  },
};

function createBaseUpdateTemplateGoldenStatusRequest(): UpdateTemplateGoldenStatusRequest {
  return { templateId: "", golden: false };
}

export const UpdateTemplateGoldenStatusRequest = {
  encode(message: UpdateTemplateGoldenStatusRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.templateId !== "") {
      writer.uint32(10).string(message.templateId);
    }
    if (message.golden !== false) {
      writer.uint32(16).bool(message.golden);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateTemplateGoldenStatusRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateTemplateGoldenStatusRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.templateId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.golden = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateTemplateGoldenStatusRequest {
    return {
      templateId: isSet(object.templateId) ? globalThis.String(object.templateId) : "",
      golden: isSet(object.golden) ? globalThis.Boolean(object.golden) : false,
    };
  },

  toJSON(message: UpdateTemplateGoldenStatusRequest): unknown {
    const obj: any = {};
    if (message.templateId !== "") {
      obj.templateId = message.templateId;
    }
    if (message.golden !== false) {
      obj.golden = message.golden;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateTemplateGoldenStatusRequest>, I>>(
    base?: I,
  ): UpdateTemplateGoldenStatusRequest {
    return UpdateTemplateGoldenStatusRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateTemplateGoldenStatusRequest>, I>>(
    object: I,
  ): UpdateTemplateGoldenStatusRequest {
    const message = createBaseUpdateTemplateGoldenStatusRequest();
    message.templateId = object.templateId ?? "";
    message.golden = object.golden ?? false;
    return message;
  },
};

function createBaseUpdateTemplateGoldenStatusResponse(): UpdateTemplateGoldenStatusResponse {
  return { templateId: "", golden: false };
}

export const UpdateTemplateGoldenStatusResponse = {
  encode(message: UpdateTemplateGoldenStatusResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.templateId !== "") {
      writer.uint32(10).string(message.templateId);
    }
    if (message.golden !== false) {
      writer.uint32(16).bool(message.golden);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateTemplateGoldenStatusResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateTemplateGoldenStatusResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.templateId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.golden = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateTemplateGoldenStatusResponse {
    return {
      templateId: isSet(object.templateId) ? globalThis.String(object.templateId) : "",
      golden: isSet(object.golden) ? globalThis.Boolean(object.golden) : false,
    };
  },

  toJSON(message: UpdateTemplateGoldenStatusResponse): unknown {
    const obj: any = {};
    if (message.templateId !== "") {
      obj.templateId = message.templateId;
    }
    if (message.golden !== false) {
      obj.golden = message.golden;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateTemplateGoldenStatusResponse>, I>>(
    base?: I,
  ): UpdateTemplateGoldenStatusResponse {
    return UpdateTemplateGoldenStatusResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateTemplateGoldenStatusResponse>, I>>(
    object: I,
  ): UpdateTemplateGoldenStatusResponse {
    const message = createBaseUpdateTemplateGoldenStatusResponse();
    message.templateId = object.templateId ?? "";
    message.golden = object.golden ?? false;
    return message;
  },
};

function createBaseUpdateTemplatePublishedStatusRequest(): UpdateTemplatePublishedStatusRequest {
  return { templateId: "", published: false };
}

export const UpdateTemplatePublishedStatusRequest = {
  encode(message: UpdateTemplatePublishedStatusRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.templateId !== "") {
      writer.uint32(10).string(message.templateId);
    }
    if (message.published !== false) {
      writer.uint32(16).bool(message.published);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateTemplatePublishedStatusRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateTemplatePublishedStatusRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.templateId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.published = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateTemplatePublishedStatusRequest {
    return {
      templateId: isSet(object.templateId) ? globalThis.String(object.templateId) : "",
      published: isSet(object.published) ? globalThis.Boolean(object.published) : false,
    };
  },

  toJSON(message: UpdateTemplatePublishedStatusRequest): unknown {
    const obj: any = {};
    if (message.templateId !== "") {
      obj.templateId = message.templateId;
    }
    if (message.published !== false) {
      obj.published = message.published;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateTemplatePublishedStatusRequest>, I>>(
    base?: I,
  ): UpdateTemplatePublishedStatusRequest {
    return UpdateTemplatePublishedStatusRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateTemplatePublishedStatusRequest>, I>>(
    object: I,
  ): UpdateTemplatePublishedStatusRequest {
    const message = createBaseUpdateTemplatePublishedStatusRequest();
    message.templateId = object.templateId ?? "";
    message.published = object.published ?? false;
    return message;
  },
};

function createBaseUpdateTemplatePublishedStatusResponse(): UpdateTemplatePublishedStatusResponse {
  return { templateId: "", published: false };
}

export const UpdateTemplatePublishedStatusResponse = {
  encode(message: UpdateTemplatePublishedStatusResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.templateId !== "") {
      writer.uint32(10).string(message.templateId);
    }
    if (message.published !== false) {
      writer.uint32(16).bool(message.published);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateTemplatePublishedStatusResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateTemplatePublishedStatusResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.templateId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.published = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateTemplatePublishedStatusResponse {
    return {
      templateId: isSet(object.templateId) ? globalThis.String(object.templateId) : "",
      published: isSet(object.published) ? globalThis.Boolean(object.published) : false,
    };
  },

  toJSON(message: UpdateTemplatePublishedStatusResponse): unknown {
    const obj: any = {};
    if (message.templateId !== "") {
      obj.templateId = message.templateId;
    }
    if (message.published !== false) {
      obj.published = message.published;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateTemplatePublishedStatusResponse>, I>>(
    base?: I,
  ): UpdateTemplatePublishedStatusResponse {
    return UpdateTemplatePublishedStatusResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateTemplatePublishedStatusResponse>, I>>(
    object: I,
  ): UpdateTemplatePublishedStatusResponse {
    const message = createBaseUpdateTemplatePublishedStatusResponse();
    message.templateId = object.templateId ?? "";
    message.published = object.published ?? false;
    return message;
  },
};

function createBaseUpdateTemplateEmrlessStatusRequest(): UpdateTemplateEmrlessStatusRequest {
  return { templateId: "", emrless: false, disableTemplateModification: false };
}

export const UpdateTemplateEmrlessStatusRequest = {
  encode(message: UpdateTemplateEmrlessStatusRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.templateId !== "") {
      writer.uint32(10).string(message.templateId);
    }
    if (message.emrless !== false) {
      writer.uint32(16).bool(message.emrless);
    }
    if (message.disableTemplateModification !== false) {
      writer.uint32(24).bool(message.disableTemplateModification);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateTemplateEmrlessStatusRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateTemplateEmrlessStatusRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.templateId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.emrless = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.disableTemplateModification = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateTemplateEmrlessStatusRequest {
    return {
      templateId: isSet(object.templateId) ? globalThis.String(object.templateId) : "",
      emrless: isSet(object.emrless) ? globalThis.Boolean(object.emrless) : false,
      disableTemplateModification: isSet(object.disableTemplateModification)
        ? globalThis.Boolean(object.disableTemplateModification)
        : false,
    };
  },

  toJSON(message: UpdateTemplateEmrlessStatusRequest): unknown {
    const obj: any = {};
    if (message.templateId !== "") {
      obj.templateId = message.templateId;
    }
    if (message.emrless !== false) {
      obj.emrless = message.emrless;
    }
    if (message.disableTemplateModification !== false) {
      obj.disableTemplateModification = message.disableTemplateModification;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateTemplateEmrlessStatusRequest>, I>>(
    base?: I,
  ): UpdateTemplateEmrlessStatusRequest {
    return UpdateTemplateEmrlessStatusRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateTemplateEmrlessStatusRequest>, I>>(
    object: I,
  ): UpdateTemplateEmrlessStatusRequest {
    const message = createBaseUpdateTemplateEmrlessStatusRequest();
    message.templateId = object.templateId ?? "";
    message.emrless = object.emrless ?? false;
    message.disableTemplateModification = object.disableTemplateModification ?? false;
    return message;
  },
};

function createBaseUpdateTemplateEmrlessStatusResponse(): UpdateTemplateEmrlessStatusResponse {
  return { templateId: "", emrless: false };
}

export const UpdateTemplateEmrlessStatusResponse = {
  encode(message: UpdateTemplateEmrlessStatusResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.templateId !== "") {
      writer.uint32(10).string(message.templateId);
    }
    if (message.emrless !== false) {
      writer.uint32(16).bool(message.emrless);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateTemplateEmrlessStatusResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateTemplateEmrlessStatusResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.templateId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.emrless = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateTemplateEmrlessStatusResponse {
    return {
      templateId: isSet(object.templateId) ? globalThis.String(object.templateId) : "",
      emrless: isSet(object.emrless) ? globalThis.Boolean(object.emrless) : false,
    };
  },

  toJSON(message: UpdateTemplateEmrlessStatusResponse): unknown {
    const obj: any = {};
    if (message.templateId !== "") {
      obj.templateId = message.templateId;
    }
    if (message.emrless !== false) {
      obj.emrless = message.emrless;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateTemplateEmrlessStatusResponse>, I>>(
    base?: I,
  ): UpdateTemplateEmrlessStatusResponse {
    return UpdateTemplateEmrlessStatusResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateTemplateEmrlessStatusResponse>, I>>(
    object: I,
  ): UpdateTemplateEmrlessStatusResponse {
    const message = createBaseUpdateTemplateEmrlessStatusResponse();
    message.templateId = object.templateId ?? "";
    message.emrless = object.emrless ?? false;
    return message;
  },
};

function createBaseUpdateTemplateRequest(): UpdateTemplateRequest {
  return { templateId: "", name: "", description: "" };
}

export const UpdateTemplateRequest = {
  encode(message: UpdateTemplateRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.templateId !== "") {
      writer.uint32(10).string(message.templateId);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.description !== "") {
      writer.uint32(26).string(message.description);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateTemplateRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateTemplateRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.templateId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateTemplateRequest {
    return {
      templateId: isSet(object.templateId) ? globalThis.String(object.templateId) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
    };
  },

  toJSON(message: UpdateTemplateRequest): unknown {
    const obj: any = {};
    if (message.templateId !== "") {
      obj.templateId = message.templateId;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateTemplateRequest>, I>>(base?: I): UpdateTemplateRequest {
    return UpdateTemplateRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateTemplateRequest>, I>>(object: I): UpdateTemplateRequest {
    const message = createBaseUpdateTemplateRequest();
    message.templateId = object.templateId ?? "";
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    return message;
  },
};

function createBaseUpdateTemplateResponse(): UpdateTemplateResponse {
  return { templateId: "", name: "", description: "" };
}

export const UpdateTemplateResponse = {
  encode(message: UpdateTemplateResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.templateId !== "") {
      writer.uint32(10).string(message.templateId);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.description !== "") {
      writer.uint32(26).string(message.description);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateTemplateResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateTemplateResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.templateId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateTemplateResponse {
    return {
      templateId: isSet(object.templateId) ? globalThis.String(object.templateId) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
    };
  },

  toJSON(message: UpdateTemplateResponse): unknown {
    const obj: any = {};
    if (message.templateId !== "") {
      obj.templateId = message.templateId;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateTemplateResponse>, I>>(base?: I): UpdateTemplateResponse {
    return UpdateTemplateResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateTemplateResponse>, I>>(object: I): UpdateTemplateResponse {
    const message = createBaseUpdateTemplateResponse();
    message.templateId = object.templateId ?? "";
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    return message;
  },
};

function createBaseDeleteTemplateRequest(): DeleteTemplateRequest {
  return { templateId: "" };
}

export const DeleteTemplateRequest = {
  encode(message: DeleteTemplateRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.templateId !== "") {
      writer.uint32(10).string(message.templateId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteTemplateRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteTemplateRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.templateId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteTemplateRequest {
    return { templateId: isSet(object.templateId) ? globalThis.String(object.templateId) : "" };
  },

  toJSON(message: DeleteTemplateRequest): unknown {
    const obj: any = {};
    if (message.templateId !== "") {
      obj.templateId = message.templateId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeleteTemplateRequest>, I>>(base?: I): DeleteTemplateRequest {
    return DeleteTemplateRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteTemplateRequest>, I>>(object: I): DeleteTemplateRequest {
    const message = createBaseDeleteTemplateRequest();
    message.templateId = object.templateId ?? "";
    return message;
  },
};

function createBaseDeleteTemplateResponse(): DeleteTemplateResponse {
  return { templateId: "", name: "", description: "", golden: false, archived: false };
}

export const DeleteTemplateResponse = {
  encode(message: DeleteTemplateResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.templateId !== "") {
      writer.uint32(10).string(message.templateId);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.description !== "") {
      writer.uint32(26).string(message.description);
    }
    if (message.golden !== false) {
      writer.uint32(32).bool(message.golden);
    }
    if (message.archived !== false) {
      writer.uint32(40).bool(message.archived);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteTemplateResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteTemplateResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.templateId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.golden = reader.bool();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.archived = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteTemplateResponse {
    return {
      templateId: isSet(object.templateId) ? globalThis.String(object.templateId) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      golden: isSet(object.golden) ? globalThis.Boolean(object.golden) : false,
      archived: isSet(object.archived) ? globalThis.Boolean(object.archived) : false,
    };
  },

  toJSON(message: DeleteTemplateResponse): unknown {
    const obj: any = {};
    if (message.templateId !== "") {
      obj.templateId = message.templateId;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.golden !== false) {
      obj.golden = message.golden;
    }
    if (message.archived !== false) {
      obj.archived = message.archived;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeleteTemplateResponse>, I>>(base?: I): DeleteTemplateResponse {
    return DeleteTemplateResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteTemplateResponse>, I>>(object: I): DeleteTemplateResponse {
    const message = createBaseDeleteTemplateResponse();
    message.templateId = object.templateId ?? "";
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    message.golden = object.golden ?? false;
    message.archived = object.archived ?? false;
    return message;
  },
};

function createBaseRefreshTemplateRequest(): RefreshTemplateRequest {
  return { templateId: "", includeObjectData: false };
}

export const RefreshTemplateRequest = {
  encode(message: RefreshTemplateRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.templateId !== "") {
      writer.uint32(10).string(message.templateId);
    }
    if (message.includeObjectData !== false) {
      writer.uint32(16).bool(message.includeObjectData);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RefreshTemplateRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRefreshTemplateRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.templateId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.includeObjectData = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RefreshTemplateRequest {
    return {
      templateId: isSet(object.templateId) ? globalThis.String(object.templateId) : "",
      includeObjectData: isSet(object.includeObjectData) ? globalThis.Boolean(object.includeObjectData) : false,
    };
  },

  toJSON(message: RefreshTemplateRequest): unknown {
    const obj: any = {};
    if (message.templateId !== "") {
      obj.templateId = message.templateId;
    }
    if (message.includeObjectData !== false) {
      obj.includeObjectData = message.includeObjectData;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RefreshTemplateRequest>, I>>(base?: I): RefreshTemplateRequest {
    return RefreshTemplateRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RefreshTemplateRequest>, I>>(object: I): RefreshTemplateRequest {
    const message = createBaseRefreshTemplateRequest();
    message.templateId = object.templateId ?? "";
    message.includeObjectData = object.includeObjectData ?? false;
    return message;
  },
};

function createBaseRefreshTemplateResponse(): RefreshTemplateResponse {
  return { templateId: "" };
}

export const RefreshTemplateResponse = {
  encode(message: RefreshTemplateResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.templateId !== "") {
      writer.uint32(10).string(message.templateId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RefreshTemplateResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRefreshTemplateResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.templateId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RefreshTemplateResponse {
    return { templateId: isSet(object.templateId) ? globalThis.String(object.templateId) : "" };
  },

  toJSON(message: RefreshTemplateResponse): unknown {
    const obj: any = {};
    if (message.templateId !== "") {
      obj.templateId = message.templateId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RefreshTemplateResponse>, I>>(base?: I): RefreshTemplateResponse {
    return RefreshTemplateResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RefreshTemplateResponse>, I>>(object: I): RefreshTemplateResponse {
    const message = createBaseRefreshTemplateResponse();
    message.templateId = object.templateId ?? "";
    return message;
  },
};

function createBaseObjectData(): ObjectData {
  return { id: "", model: "", numberRecords: 0, status: "", createdAt: undefined, updatedAt: undefined };
}

export const ObjectData = {
  encode(message: ObjectData, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.model !== "") {
      writer.uint32(18).string(message.model);
    }
    if (message.numberRecords !== 0) {
      writer.uint32(24).int64(message.numberRecords);
    }
    if (message.status !== "") {
      writer.uint32(34).string(message.status);
    }
    if (message.createdAt !== undefined) {
      Timestamp.encode(toTimestamp(message.createdAt), writer.uint32(42).fork()).ldelim();
    }
    if (message.updatedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.updatedAt), writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ObjectData {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseObjectData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.model = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.numberRecords = longToNumber(reader.int64() as Long);
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.status = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.createdAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.updatedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ObjectData {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      model: isSet(object.model) ? globalThis.String(object.model) : "",
      numberRecords: isSet(object.numberRecords) ? globalThis.Number(object.numberRecords) : 0,
      status: isSet(object.status) ? globalThis.String(object.status) : "",
      createdAt: isSet(object.createdAt) ? fromJsonTimestamp(object.createdAt) : undefined,
      updatedAt: isSet(object.updatedAt) ? fromJsonTimestamp(object.updatedAt) : undefined,
    };
  },

  toJSON(message: ObjectData): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.model !== "") {
      obj.model = message.model;
    }
    if (message.numberRecords !== 0) {
      obj.numberRecords = Math.round(message.numberRecords);
    }
    if (message.status !== "") {
      obj.status = message.status;
    }
    if (message.createdAt !== undefined) {
      obj.createdAt = message.createdAt.toISOString();
    }
    if (message.updatedAt !== undefined) {
      obj.updatedAt = message.updatedAt.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ObjectData>, I>>(base?: I): ObjectData {
    return ObjectData.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ObjectData>, I>>(object: I): ObjectData {
    const message = createBaseObjectData();
    message.id = object.id ?? "";
    message.model = object.model ?? "";
    message.numberRecords = object.numberRecords ?? 0;
    message.status = object.status ?? "";
    message.createdAt = object.createdAt ?? undefined;
    message.updatedAt = object.updatedAt ?? undefined;
    return message;
  },
};

function createBaseUploadObjectDataRequest(): UploadObjectDataRequest {
  return { templateId: "", data: undefined, referenceId: undefined };
}

export const UploadObjectDataRequest = {
  encode(message: UploadObjectDataRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.templateId !== "") {
      writer.uint32(10).string(message.templateId);
    }
    if (message.data !== undefined) {
      writer.uint32(18).bytes(message.data);
    }
    if (message.referenceId !== undefined) {
      writer.uint32(26).string(message.referenceId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UploadObjectDataRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUploadObjectDataRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.templateId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.data = reader.bytes();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.referenceId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UploadObjectDataRequest {
    return {
      templateId: isSet(object.templateId) ? globalThis.String(object.templateId) : "",
      data: isSet(object.data) ? bytesFromBase64(object.data) : undefined,
      referenceId: isSet(object.referenceId) ? globalThis.String(object.referenceId) : undefined,
    };
  },

  toJSON(message: UploadObjectDataRequest): unknown {
    const obj: any = {};
    if (message.templateId !== "") {
      obj.templateId = message.templateId;
    }
    if (message.data !== undefined) {
      obj.data = base64FromBytes(message.data);
    }
    if (message.referenceId !== undefined) {
      obj.referenceId = message.referenceId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UploadObjectDataRequest>, I>>(base?: I): UploadObjectDataRequest {
    return UploadObjectDataRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UploadObjectDataRequest>, I>>(object: I): UploadObjectDataRequest {
    const message = createBaseUploadObjectDataRequest();
    message.templateId = object.templateId ?? "";
    message.data = object.data ?? undefined;
    message.referenceId = object.referenceId ?? undefined;
    return message;
  },
};

function createBaseGetObjectDataUploadUrlRequest(): GetObjectDataUploadUrlRequest {
  return { templateId: undefined };
}

export const GetObjectDataUploadUrlRequest = {
  encode(message: GetObjectDataUploadUrlRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.templateId !== undefined) {
      writer.uint32(10).string(message.templateId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetObjectDataUploadUrlRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetObjectDataUploadUrlRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.templateId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetObjectDataUploadUrlRequest {
    return { templateId: isSet(object.templateId) ? globalThis.String(object.templateId) : undefined };
  },

  toJSON(message: GetObjectDataUploadUrlRequest): unknown {
    const obj: any = {};
    if (message.templateId !== undefined) {
      obj.templateId = message.templateId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetObjectDataUploadUrlRequest>, I>>(base?: I): GetObjectDataUploadUrlRequest {
    return GetObjectDataUploadUrlRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetObjectDataUploadUrlRequest>, I>>(
    object: I,
  ): GetObjectDataUploadUrlRequest {
    const message = createBaseGetObjectDataUploadUrlRequest();
    message.templateId = object.templateId ?? undefined;
    return message;
  },
};

function createBaseGetObjectDataUploadUrlResponse(): GetObjectDataUploadUrlResponse {
  return { url: "", referenceId: "", headers: {} };
}

export const GetObjectDataUploadUrlResponse = {
  encode(message: GetObjectDataUploadUrlResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.url !== "") {
      writer.uint32(10).string(message.url);
    }
    if (message.referenceId !== "") {
      writer.uint32(18).string(message.referenceId);
    }
    Object.entries(message.headers).forEach(([key, value]) => {
      GetObjectDataUploadUrlResponse_HeadersEntry.encode({ key: key as any, value }, writer.uint32(26).fork()).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetObjectDataUploadUrlResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetObjectDataUploadUrlResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.url = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.referenceId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          const entry3 = GetObjectDataUploadUrlResponse_HeadersEntry.decode(reader, reader.uint32());
          if (entry3.value !== undefined) {
            message.headers[entry3.key] = entry3.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetObjectDataUploadUrlResponse {
    return {
      url: isSet(object.url) ? globalThis.String(object.url) : "",
      referenceId: isSet(object.referenceId) ? globalThis.String(object.referenceId) : "",
      headers: isObject(object.headers)
        ? Object.entries(object.headers).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: GetObjectDataUploadUrlResponse): unknown {
    const obj: any = {};
    if (message.url !== "") {
      obj.url = message.url;
    }
    if (message.referenceId !== "") {
      obj.referenceId = message.referenceId;
    }
    if (message.headers) {
      const entries = Object.entries(message.headers);
      if (entries.length > 0) {
        obj.headers = {};
        entries.forEach(([k, v]) => {
          obj.headers[k] = v;
        });
      }
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetObjectDataUploadUrlResponse>, I>>(base?: I): GetObjectDataUploadUrlResponse {
    return GetObjectDataUploadUrlResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetObjectDataUploadUrlResponse>, I>>(
    object: I,
  ): GetObjectDataUploadUrlResponse {
    const message = createBaseGetObjectDataUploadUrlResponse();
    message.url = object.url ?? "";
    message.referenceId = object.referenceId ?? "";
    message.headers = Object.entries(object.headers ?? {}).reduce<{ [key: string]: string }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = globalThis.String(value);
      }
      return acc;
    }, {});
    return message;
  },
};

function createBaseGetObjectDataUploadUrlResponse_HeadersEntry(): GetObjectDataUploadUrlResponse_HeadersEntry {
  return { key: "", value: "" };
}

export const GetObjectDataUploadUrlResponse_HeadersEntry = {
  encode(message: GetObjectDataUploadUrlResponse_HeadersEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetObjectDataUploadUrlResponse_HeadersEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetObjectDataUploadUrlResponse_HeadersEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetObjectDataUploadUrlResponse_HeadersEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? globalThis.String(object.value) : "",
    };
  },

  toJSON(message: GetObjectDataUploadUrlResponse_HeadersEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetObjectDataUploadUrlResponse_HeadersEntry>, I>>(
    base?: I,
  ): GetObjectDataUploadUrlResponse_HeadersEntry {
    return GetObjectDataUploadUrlResponse_HeadersEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetObjectDataUploadUrlResponse_HeadersEntry>, I>>(
    object: I,
  ): GetObjectDataUploadUrlResponse_HeadersEntry {
    const message = createBaseGetObjectDataUploadUrlResponse_HeadersEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseUploadObjectDataResponse(): UploadObjectDataResponse {
  return { objectData: [] };
}

export const UploadObjectDataResponse = {
  encode(message: UploadObjectDataResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.objectData) {
      ObjectData.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UploadObjectDataResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUploadObjectDataResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.objectData.push(ObjectData.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UploadObjectDataResponse {
    return {
      objectData: globalThis.Array.isArray(object?.objectData)
        ? object.objectData.map((e: any) => ObjectData.fromJSON(e))
        : [],
    };
  },

  toJSON(message: UploadObjectDataResponse): unknown {
    const obj: any = {};
    if (message.objectData?.length) {
      obj.objectData = message.objectData.map((e) => ObjectData.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UploadObjectDataResponse>, I>>(base?: I): UploadObjectDataResponse {
    return UploadObjectDataResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UploadObjectDataResponse>, I>>(object: I): UploadObjectDataResponse {
    const message = createBaseUploadObjectDataResponse();
    message.objectData = object.objectData?.map((e) => ObjectData.fromPartial(e)) || [];
    return message;
  },
};

function createBaseListObjectDBClustersRequest(): ListObjectDBClustersRequest {
  return {};
}

export const ListObjectDBClustersRequest = {
  encode(_: ListObjectDBClustersRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListObjectDBClustersRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListObjectDBClustersRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): ListObjectDBClustersRequest {
    return {};
  },

  toJSON(_: ListObjectDBClustersRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<ListObjectDBClustersRequest>, I>>(base?: I): ListObjectDBClustersRequest {
    return ListObjectDBClustersRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListObjectDBClustersRequest>, I>>(_: I): ListObjectDBClustersRequest {
    const message = createBaseListObjectDBClustersRequest();
    return message;
  },
};

function createBaseListObjectDBClustersResponse(): ListObjectDBClustersResponse {
  return { dbClusters: [] };
}

export const ListObjectDBClustersResponse = {
  encode(message: ListObjectDBClustersResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.dbClusters) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListObjectDBClustersResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListObjectDBClustersResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.dbClusters.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListObjectDBClustersResponse {
    return {
      dbClusters: globalThis.Array.isArray(object?.dbClusters)
        ? object.dbClusters.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: ListObjectDBClustersResponse): unknown {
    const obj: any = {};
    if (message.dbClusters?.length) {
      obj.dbClusters = message.dbClusters;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListObjectDBClustersResponse>, I>>(base?: I): ListObjectDBClustersResponse {
    return ListObjectDBClustersResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListObjectDBClustersResponse>, I>>(object: I): ListObjectDBClustersResponse {
    const message = createBaseListObjectDBClustersResponse();
    message.dbClusters = object.dbClusters?.map((e) => e) || [];
    return message;
  },
};

function createBaseTendoObjectDefinition(): TendoObjectDefinition {
  return { name: "" };
}

export const TendoObjectDefinition = {
  encode(message: TendoObjectDefinition, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TendoObjectDefinition {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTendoObjectDefinition();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TendoObjectDefinition {
    return { name: isSet(object.name) ? globalThis.String(object.name) : "" };
  },

  toJSON(message: TendoObjectDefinition): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TendoObjectDefinition>, I>>(base?: I): TendoObjectDefinition {
    return TendoObjectDefinition.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TendoObjectDefinition>, I>>(object: I): TendoObjectDefinition {
    const message = createBaseTendoObjectDefinition();
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseListObjectDefinitionsRequest(): ListObjectDefinitionsRequest {
  return { tenantId: "" };
}

export const ListObjectDefinitionsRequest = {
  encode(message: ListObjectDefinitionsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tenantId !== "") {
      writer.uint32(10).string(message.tenantId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListObjectDefinitionsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListObjectDefinitionsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tenantId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListObjectDefinitionsRequest {
    return { tenantId: isSet(object.tenantId) ? globalThis.String(object.tenantId) : "" };
  },

  toJSON(message: ListObjectDefinitionsRequest): unknown {
    const obj: any = {};
    if (message.tenantId !== "") {
      obj.tenantId = message.tenantId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListObjectDefinitionsRequest>, I>>(base?: I): ListObjectDefinitionsRequest {
    return ListObjectDefinitionsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListObjectDefinitionsRequest>, I>>(object: I): ListObjectDefinitionsRequest {
    const message = createBaseListObjectDefinitionsRequest();
    message.tenantId = object.tenantId ?? "";
    return message;
  },
};

function createBaseListObjectDefinitionsResponse(): ListObjectDefinitionsResponse {
  return { objectDefinitions: [] };
}

export const ListObjectDefinitionsResponse = {
  encode(message: ListObjectDefinitionsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.objectDefinitions) {
      TendoObjectDefinition.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListObjectDefinitionsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListObjectDefinitionsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.objectDefinitions.push(TendoObjectDefinition.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListObjectDefinitionsResponse {
    return {
      objectDefinitions: globalThis.Array.isArray(object?.objectDefinitions)
        ? object.objectDefinitions.map((e: any) => TendoObjectDefinition.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ListObjectDefinitionsResponse): unknown {
    const obj: any = {};
    if (message.objectDefinitions?.length) {
      obj.objectDefinitions = message.objectDefinitions.map((e) => TendoObjectDefinition.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListObjectDefinitionsResponse>, I>>(base?: I): ListObjectDefinitionsResponse {
    return ListObjectDefinitionsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListObjectDefinitionsResponse>, I>>(
    object: I,
  ): ListObjectDefinitionsResponse {
    const message = createBaseListObjectDefinitionsResponse();
    message.objectDefinitions = object.objectDefinitions?.map((e) => TendoObjectDefinition.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetObjectDataRequest(): GetObjectDataRequest {
  return { templateId: "" };
}

export const GetObjectDataRequest = {
  encode(message: GetObjectDataRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.templateId !== "") {
      writer.uint32(10).string(message.templateId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetObjectDataRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetObjectDataRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.templateId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetObjectDataRequest {
    return { templateId: isSet(object.templateId) ? globalThis.String(object.templateId) : "" };
  },

  toJSON(message: GetObjectDataRequest): unknown {
    const obj: any = {};
    if (message.templateId !== "") {
      obj.templateId = message.templateId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetObjectDataRequest>, I>>(base?: I): GetObjectDataRequest {
    return GetObjectDataRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetObjectDataRequest>, I>>(object: I): GetObjectDataRequest {
    const message = createBaseGetObjectDataRequest();
    message.templateId = object.templateId ?? "";
    return message;
  },
};

function createBaseGetObjectDataResponse(): GetObjectDataResponse {
  return { objectData: [] };
}

export const GetObjectDataResponse = {
  encode(message: GetObjectDataResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.objectData) {
      ObjectData.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetObjectDataResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetObjectDataResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.objectData.push(ObjectData.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetObjectDataResponse {
    return {
      objectData: globalThis.Array.isArray(object?.objectData)
        ? object.objectData.map((e: any) => ObjectData.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GetObjectDataResponse): unknown {
    const obj: any = {};
    if (message.objectData?.length) {
      obj.objectData = message.objectData.map((e) => ObjectData.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetObjectDataResponse>, I>>(base?: I): GetObjectDataResponse {
    return GetObjectDataResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetObjectDataResponse>, I>>(object: I): GetObjectDataResponse {
    const message = createBaseGetObjectDataResponse();
    message.objectData = object.objectData?.map((e) => ObjectData.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetObjectDataPackageRequest(): GetObjectDataPackageRequest {
  return { templateId: "" };
}

export const GetObjectDataPackageRequest = {
  encode(message: GetObjectDataPackageRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.templateId !== "") {
      writer.uint32(10).string(message.templateId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetObjectDataPackageRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetObjectDataPackageRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.templateId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetObjectDataPackageRequest {
    return { templateId: isSet(object.templateId) ? globalThis.String(object.templateId) : "" };
  },

  toJSON(message: GetObjectDataPackageRequest): unknown {
    const obj: any = {};
    if (message.templateId !== "") {
      obj.templateId = message.templateId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetObjectDataPackageRequest>, I>>(base?: I): GetObjectDataPackageRequest {
    return GetObjectDataPackageRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetObjectDataPackageRequest>, I>>(object: I): GetObjectDataPackageRequest {
    const message = createBaseGetObjectDataPackageRequest();
    message.templateId = object.templateId ?? "";
    return message;
  },
};

function createBaseGetObjectDataPackageResponse(): GetObjectDataPackageResponse {
  return { downloadUrl: "" };
}

export const GetObjectDataPackageResponse = {
  encode(message: GetObjectDataPackageResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.downloadUrl !== "") {
      writer.uint32(10).string(message.downloadUrl);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetObjectDataPackageResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetObjectDataPackageResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.downloadUrl = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetObjectDataPackageResponse {
    return { downloadUrl: isSet(object.downloadUrl) ? globalThis.String(object.downloadUrl) : "" };
  },

  toJSON(message: GetObjectDataPackageResponse): unknown {
    const obj: any = {};
    if (message.downloadUrl !== "") {
      obj.downloadUrl = message.downloadUrl;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetObjectDataPackageResponse>, I>>(base?: I): GetObjectDataPackageResponse {
    return GetObjectDataPackageResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetObjectDataPackageResponse>, I>>(object: I): GetObjectDataPackageResponse {
    const message = createBaseGetObjectDataPackageResponse();
    message.downloadUrl = object.downloadUrl ?? "";
    return message;
  },
};

function createBaseObjectDataDeployment(): ObjectDataDeployment {
  return { id: "", model: "", numberRecords: 0, status: "", createdAt: undefined, updatedAt: undefined };
}

export const ObjectDataDeployment = {
  encode(message: ObjectDataDeployment, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.model !== "") {
      writer.uint32(18).string(message.model);
    }
    if (message.numberRecords !== 0) {
      writer.uint32(24).int64(message.numberRecords);
    }
    if (message.status !== "") {
      writer.uint32(34).string(message.status);
    }
    if (message.createdAt !== undefined) {
      Timestamp.encode(toTimestamp(message.createdAt), writer.uint32(42).fork()).ldelim();
    }
    if (message.updatedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.updatedAt), writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ObjectDataDeployment {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseObjectDataDeployment();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.model = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.numberRecords = longToNumber(reader.int64() as Long);
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.status = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.createdAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.updatedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ObjectDataDeployment {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      model: isSet(object.model) ? globalThis.String(object.model) : "",
      numberRecords: isSet(object.numberRecords) ? globalThis.Number(object.numberRecords) : 0,
      status: isSet(object.status) ? globalThis.String(object.status) : "",
      createdAt: isSet(object.createdAt) ? fromJsonTimestamp(object.createdAt) : undefined,
      updatedAt: isSet(object.updatedAt) ? fromJsonTimestamp(object.updatedAt) : undefined,
    };
  },

  toJSON(message: ObjectDataDeployment): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.model !== "") {
      obj.model = message.model;
    }
    if (message.numberRecords !== 0) {
      obj.numberRecords = Math.round(message.numberRecords);
    }
    if (message.status !== "") {
      obj.status = message.status;
    }
    if (message.createdAt !== undefined) {
      obj.createdAt = message.createdAt.toISOString();
    }
    if (message.updatedAt !== undefined) {
      obj.updatedAt = message.updatedAt.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ObjectDataDeployment>, I>>(base?: I): ObjectDataDeployment {
    return ObjectDataDeployment.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ObjectDataDeployment>, I>>(object: I): ObjectDataDeployment {
    const message = createBaseObjectDataDeployment();
    message.id = object.id ?? "";
    message.model = object.model ?? "";
    message.numberRecords = object.numberRecords ?? 0;
    message.status = object.status ?? "";
    message.createdAt = object.createdAt ?? undefined;
    message.updatedAt = object.updatedAt ?? undefined;
    return message;
  },
};

function createBaseGetObjectDataDeploymentsRequest(): GetObjectDataDeploymentsRequest {
  return { tenantId: "" };
}

export const GetObjectDataDeploymentsRequest = {
  encode(message: GetObjectDataDeploymentsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tenantId !== "") {
      writer.uint32(10).string(message.tenantId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetObjectDataDeploymentsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetObjectDataDeploymentsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tenantId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetObjectDataDeploymentsRequest {
    return { tenantId: isSet(object.tenantId) ? globalThis.String(object.tenantId) : "" };
  },

  toJSON(message: GetObjectDataDeploymentsRequest): unknown {
    const obj: any = {};
    if (message.tenantId !== "") {
      obj.tenantId = message.tenantId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetObjectDataDeploymentsRequest>, I>>(base?: I): GetObjectDataDeploymentsRequest {
    return GetObjectDataDeploymentsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetObjectDataDeploymentsRequest>, I>>(
    object: I,
  ): GetObjectDataDeploymentsRequest {
    const message = createBaseGetObjectDataDeploymentsRequest();
    message.tenantId = object.tenantId ?? "";
    return message;
  },
};

function createBaseGetObjectDataDeploymentsResponse(): GetObjectDataDeploymentsResponse {
  return { objectDataDeployments: [] };
}

export const GetObjectDataDeploymentsResponse = {
  encode(message: GetObjectDataDeploymentsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.objectDataDeployments) {
      ObjectDataDeployment.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetObjectDataDeploymentsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetObjectDataDeploymentsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.objectDataDeployments.push(ObjectDataDeployment.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetObjectDataDeploymentsResponse {
    return {
      objectDataDeployments: globalThis.Array.isArray(object?.objectDataDeployments)
        ? object.objectDataDeployments.map((e: any) => ObjectDataDeployment.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GetObjectDataDeploymentsResponse): unknown {
    const obj: any = {};
    if (message.objectDataDeployments?.length) {
      obj.objectDataDeployments = message.objectDataDeployments.map((e) => ObjectDataDeployment.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetObjectDataDeploymentsResponse>, I>>(
    base?: I,
  ): GetObjectDataDeploymentsResponse {
    return GetObjectDataDeploymentsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetObjectDataDeploymentsResponse>, I>>(
    object: I,
  ): GetObjectDataDeploymentsResponse {
    const message = createBaseGetObjectDataDeploymentsResponse();
    message.objectDataDeployments = object.objectDataDeployments?.map((e) => ObjectDataDeployment.fromPartial(e)) || [];
    return message;
  },
};

function createBaseDeployObjectDataRequest(): DeployObjectDataRequest {
  return { tenantId: "", objectDataIds: [] };
}

export const DeployObjectDataRequest = {
  encode(message: DeployObjectDataRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tenantId !== "") {
      writer.uint32(10).string(message.tenantId);
    }
    for (const v of message.objectDataIds) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeployObjectDataRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeployObjectDataRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tenantId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.objectDataIds.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeployObjectDataRequest {
    return {
      tenantId: isSet(object.tenantId) ? globalThis.String(object.tenantId) : "",
      objectDataIds: globalThis.Array.isArray(object?.objectDataIds)
        ? object.objectDataIds.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: DeployObjectDataRequest): unknown {
    const obj: any = {};
    if (message.tenantId !== "") {
      obj.tenantId = message.tenantId;
    }
    if (message.objectDataIds?.length) {
      obj.objectDataIds = message.objectDataIds;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeployObjectDataRequest>, I>>(base?: I): DeployObjectDataRequest {
    return DeployObjectDataRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeployObjectDataRequest>, I>>(object: I): DeployObjectDataRequest {
    const message = createBaseDeployObjectDataRequest();
    message.tenantId = object.tenantId ?? "";
    message.objectDataIds = object.objectDataIds?.map((e) => e) || [];
    return message;
  },
};

function createBaseDeployObjectDataResponse(): DeployObjectDataResponse {
  return { objectDataDeployments: [] };
}

export const DeployObjectDataResponse = {
  encode(message: DeployObjectDataResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.objectDataDeployments) {
      ObjectDataDeployment.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeployObjectDataResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeployObjectDataResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.objectDataDeployments.push(ObjectDataDeployment.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeployObjectDataResponse {
    return {
      objectDataDeployments: globalThis.Array.isArray(object?.objectDataDeployments)
        ? object.objectDataDeployments.map((e: any) => ObjectDataDeployment.fromJSON(e))
        : [],
    };
  },

  toJSON(message: DeployObjectDataResponse): unknown {
    const obj: any = {};
    if (message.objectDataDeployments?.length) {
      obj.objectDataDeployments = message.objectDataDeployments.map((e) => ObjectDataDeployment.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeployObjectDataResponse>, I>>(base?: I): DeployObjectDataResponse {
    return DeployObjectDataResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeployObjectDataResponse>, I>>(object: I): DeployObjectDataResponse {
    const message = createBaseDeployObjectDataResponse();
    message.objectDataDeployments = object.objectDataDeployments?.map((e) => ObjectDataDeployment.fromPartial(e)) || [];
    return message;
  },
};

function createBaseUserProvisioningDeployment(): UserProvisioningDeployment {
  return { id: "", status: "", errorMessage: "", executionId: "", demoUserEmail: "" };
}

export const UserProvisioningDeployment = {
  encode(message: UserProvisioningDeployment, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.status !== "") {
      writer.uint32(18).string(message.status);
    }
    if (message.errorMessage !== "") {
      writer.uint32(26).string(message.errorMessage);
    }
    if (message.executionId !== "") {
      writer.uint32(34).string(message.executionId);
    }
    if (message.demoUserEmail !== "") {
      writer.uint32(42).string(message.demoUserEmail);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserProvisioningDeployment {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserProvisioningDeployment();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.status = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.errorMessage = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.executionId = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.demoUserEmail = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserProvisioningDeployment {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      status: isSet(object.status) ? globalThis.String(object.status) : "",
      errorMessage: isSet(object.errorMessage) ? globalThis.String(object.errorMessage) : "",
      executionId: isSet(object.executionId) ? globalThis.String(object.executionId) : "",
      demoUserEmail: isSet(object.demoUserEmail) ? globalThis.String(object.demoUserEmail) : "",
    };
  },

  toJSON(message: UserProvisioningDeployment): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.status !== "") {
      obj.status = message.status;
    }
    if (message.errorMessage !== "") {
      obj.errorMessage = message.errorMessage;
    }
    if (message.executionId !== "") {
      obj.executionId = message.executionId;
    }
    if (message.demoUserEmail !== "") {
      obj.demoUserEmail = message.demoUserEmail;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UserProvisioningDeployment>, I>>(base?: I): UserProvisioningDeployment {
    return UserProvisioningDeployment.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UserProvisioningDeployment>, I>>(object: I): UserProvisioningDeployment {
    const message = createBaseUserProvisioningDeployment();
    message.id = object.id ?? "";
    message.status = object.status ?? "";
    message.errorMessage = object.errorMessage ?? "";
    message.executionId = object.executionId ?? "";
    message.demoUserEmail = object.demoUserEmail ?? "";
    return message;
  },
};

function createBaseGetUserProvisioningDeploymentsRequest(): GetUserProvisioningDeploymentsRequest {
  return { tenantId: "" };
}

export const GetUserProvisioningDeploymentsRequest = {
  encode(message: GetUserProvisioningDeploymentsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tenantId !== "") {
      writer.uint32(10).string(message.tenantId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetUserProvisioningDeploymentsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetUserProvisioningDeploymentsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tenantId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetUserProvisioningDeploymentsRequest {
    return { tenantId: isSet(object.tenantId) ? globalThis.String(object.tenantId) : "" };
  },

  toJSON(message: GetUserProvisioningDeploymentsRequest): unknown {
    const obj: any = {};
    if (message.tenantId !== "") {
      obj.tenantId = message.tenantId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetUserProvisioningDeploymentsRequest>, I>>(
    base?: I,
  ): GetUserProvisioningDeploymentsRequest {
    return GetUserProvisioningDeploymentsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetUserProvisioningDeploymentsRequest>, I>>(
    object: I,
  ): GetUserProvisioningDeploymentsRequest {
    const message = createBaseGetUserProvisioningDeploymentsRequest();
    message.tenantId = object.tenantId ?? "";
    return message;
  },
};

function createBaseGetUserProvisioningDeploymentsResponse(): GetUserProvisioningDeploymentsResponse {
  return { userProvisioningDeployments: [] };
}

export const GetUserProvisioningDeploymentsResponse = {
  encode(message: GetUserProvisioningDeploymentsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.userProvisioningDeployments) {
      UserProvisioningDeployment.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetUserProvisioningDeploymentsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetUserProvisioningDeploymentsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.userProvisioningDeployments.push(UserProvisioningDeployment.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetUserProvisioningDeploymentsResponse {
    return {
      userProvisioningDeployments: globalThis.Array.isArray(object?.userProvisioningDeployments)
        ? object.userProvisioningDeployments.map((e: any) => UserProvisioningDeployment.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GetUserProvisioningDeploymentsResponse): unknown {
    const obj: any = {};
    if (message.userProvisioningDeployments?.length) {
      obj.userProvisioningDeployments = message.userProvisioningDeployments.map((e) =>
        UserProvisioningDeployment.toJSON(e)
      );
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetUserProvisioningDeploymentsResponse>, I>>(
    base?: I,
  ): GetUserProvisioningDeploymentsResponse {
    return GetUserProvisioningDeploymentsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetUserProvisioningDeploymentsResponse>, I>>(
    object: I,
  ): GetUserProvisioningDeploymentsResponse {
    const message = createBaseGetUserProvisioningDeploymentsResponse();
    message.userProvisioningDeployments =
      object.userProvisioningDeployments?.map((e) => UserProvisioningDeployment.fromPartial(e)) || [];
    return message;
  },
};

export interface TendoCloudConfiguratorService {
  CreateCustomer(request: CreateCustomerRequest): Promise<CreateCustomerResponse>;
  GetCustomers(request: GetCustomersRequest): Promise<GetCustomersResponse>;
  GetCustomer(request: GetCustomerRequest): Promise<GetCustomerResponse>;
  UpdateCustomerStatus(request: UpdateCustomerStatusRequest): Promise<UpdateCustomerStatusResponse>;
  GetTenants(request: GetTenantsRequest): Promise<GetTenantsResponse>;
  GetTenant(request: GetTenantRequest): Promise<GetTenantResponse>;
  GetTenantDeploymentStatus(request: GetTenantDeploymentStatusRequest): Promise<GetTenantDeploymentStatusResponse>;
  GetTenantTemplates(request: GetTenantTemplatesRequest): Promise<GetTenantTemplatesResponse>;
  GetTemplates(request: GetTemplatesRequest): Promise<GetTemplatesResponse>;
  GetTemplate(request: GetTemplateRequest): Promise<GetTemplateResponse>;
  GetTemplateCreationStatus(request: GetTemplateCreationStatusRequest): Promise<GetTemplateCreationStatusResponse>;
  CreateTenantFromTemplate(request: CreateTenantFromTemplateRequest): Promise<CreateTenantFromTemplateResponse>;
  GetRenderedTemplate(request: GetRenderedTemplateRequest): Promise<GetRenderedTemplateResponse>;
  GetRenderedTemplateFragment(
    request: GetRenderedTemplateFragmentRequest,
  ): Promise<GetRenderedTemplateFragmentResponse>;
  UploadTemplate(request: UploadTemplateRequest): Promise<UploadTemplateResponse>;
  UpdateTemplate(request: UpdateTemplateRequest): Promise<UpdateTemplateResponse>;
  UpdateTemplateGoldenStatus(request: UpdateTemplateGoldenStatusRequest): Promise<UpdateTemplateGoldenStatusResponse>;
  UpdateTemplatePublishedStatus(
    request: UpdateTemplatePublishedStatusRequest,
  ): Promise<UpdateTemplatePublishedStatusResponse>;
  UpdateTemplateEmrlessStatus(
    request: UpdateTemplateEmrlessStatusRequest,
  ): Promise<UpdateTemplateEmrlessStatusResponse>;
  DeleteTemplate(request: DeleteTemplateRequest): Promise<DeleteTemplateResponse>;
  RefreshTemplate(request: RefreshTemplateRequest): Promise<RefreshTemplateResponse>;
  DestroyTenant(request: DestroyTenantRequest): Promise<DestroyTenantResponse>;
  DeleteTenant(request: DeleteTenantRequest): Promise<DeleteTenantResponse>;
  GetTenantDestructions(request: GetTenantDestructionsRequest): Promise<GetTenantDestructionsResponse>;
  GetTemplateFragmentDeployments(
    request: GetTemplateFragmentDeploymentsRequest,
  ): Promise<GetTemplateFragmentDeploymentsResponse>;
  InitiateTenantExport(request: InitiateTenantExportRequest): Promise<InitiateTenantExportResponse>;
  ListObjectDBClusters(request: ListObjectDBClustersRequest): Promise<ListObjectDBClustersResponse>;
  ListObjectDefinitions(request: ListObjectDefinitionsRequest): Promise<ListObjectDefinitionsResponse>;
  UploadObjectData(request: UploadObjectDataRequest): Promise<UploadObjectDataResponse>;
  DeployObjectData(request: DeployObjectDataRequest): Promise<DeployObjectDataResponse>;
  GetObjectData(request: GetObjectDataRequest): Promise<GetObjectDataResponse>;
  GetObjectDataPackage(request: GetObjectDataPackageRequest): Promise<GetObjectDataPackageResponse>;
  GetObjectDataDeployments(request: GetObjectDataDeploymentsRequest): Promise<GetObjectDataDeploymentsResponse>;
  GetObjectDataUploadUrl(request: GetObjectDataUploadUrlRequest): Promise<GetObjectDataUploadUrlResponse>;
  GetUserProvisioningDeployments(
    request: GetUserProvisioningDeploymentsRequest,
  ): Promise<GetUserProvisioningDeploymentsResponse>;
}

function bytesFromBase64(b64: string): Uint8Array {
  if ((globalThis as any).Buffer) {
    return Uint8Array.from(globalThis.Buffer.from(b64, "base64"));
  } else {
    const bin = globalThis.atob(b64);
    const arr = new Uint8Array(bin.length);
    for (let i = 0; i < bin.length; ++i) {
      arr[i] = bin.charCodeAt(i);
    }
    return arr;
  }
}

function base64FromBytes(arr: Uint8Array): string {
  if ((globalThis as any).Buffer) {
    return globalThis.Buffer.from(arr).toString("base64");
  } else {
    const bin: string[] = [];
    arr.forEach((byte) => {
      bin.push(globalThis.String.fromCharCode(byte));
    });
    return globalThis.btoa(bin.join(""));
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = Math.trunc(date.getTime() / 1_000);
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
