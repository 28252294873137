import { Box, FormField, FileUpload, SpaceBetween, Table, ExpandableSection } from '@cloudscape-design/components'
import { useState } from 'react'
import { unzip } from 'unzipit'

export interface UploadObjectDataProps {
  onFileSelect: (f?: File) => void
  defaultExpanded?: boolean
}

export const UploadObjectData = ({ onFileSelect, defaultExpanded }: UploadObjectDataProps) => {
  const [f, setF] = useState<File>()
  const [zippedFilenames, setZippedFileNames] = useState<string[]>([])
  const [attachmentNames, setAttachmentNames] = useState<string[]>([])
  const handleFileChange = async (fs: File[]) => {
    if (fs.length > 1) {
      throw new Error('unexpected multifile upload')
    }
    const f = fs.length === 1 ? fs[0] : undefined
    setF(f)

    if (f) {
      const { entries } = await unzip(f!)
      const fns = Object.entries(entries).map(([name]) => name)
      setZippedFileNames(fns.filter((name) => !name.endsWith('.zip') && !name.endsWith('.json')))
      setAttachmentNames(fns.filter((name) => name.endsWith('.zip')))
    } else {
      setZippedFileNames([])
    }

    onFileSelect(f)
  }

  const sortedZippedFilenames = zippedFilenames.sort((a, b) => a.localeCompare(b))

  return (
    <Box>
      <SpaceBetween direction="vertical" size="xs">
        <FormField label="Select a zip containing CSV files">
          <FileUpload
            accept=".zip"
            onChange={({ detail }) => handleFileChange(detail.value)}
            value={f ? [f] : []}
            i18nStrings={{
              uploadButtonText: (e) => (e ? 'Choose files' : 'Choose file'),
              dropzoneText: (e) => (e ? 'Drop files to upload' : 'Drop file to upload'),
              removeFileAriaLabel: (e) => `Remove file ${e + 1}`,
              limitShowFewer: 'Show fewer files',
              limitShowMore: 'Show more files',
              errorIconAriaLabel: 'Error',
            }}
            showFileLastModified
            showFileSize
          />
        </FormField>
        {f && (
          <ExpandableSection headerText="Objects" defaultExpanded={defaultExpanded}>
            <Box padding={{ left: 'm', right: 'm' }}>
              <Table
                contentDensity="compact"
                columnDefinitions={[
                  {
                    id: 'name',
                    header: 'Name',
                    cell: (item) => item.replace('.csv', ''),
                  },
                ]}
                items={sortedZippedFilenames}
                variant="borderless"
                empty={
                  <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
                    <SpaceBetween size="m">
                      <b>No objects found</b>
                    </SpaceBetween>
                  </Box>
                }
              />
            </Box>
          </ExpandableSection>
        )}
        {f && attachmentNames.length > 0 && (
          <ExpandableSection headerText="Attachments" defaultExpanded={defaultExpanded}>
            <Box padding={{ left: 'm', right: 'm' }}>
              <Table
                contentDensity="compact"
                columnDefinitions={[
                  {
                    id: 'object',
                    header: 'Object',
                    cell: (item) => item.replace('_attachments.zip', ''),
                  },
                ]}
                items={attachmentNames.sort((a, b) => a.localeCompare(b))}
                variant="borderless"
                empty={
                  <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
                    <SpaceBetween size="m">
                      <b>No attachments found</b>
                    </SpaceBetween>
                  </Box>
                }
              />
            </Box>
          </ExpandableSection>
        )}
      </SpaceBetween>
    </Box>
  )
}
